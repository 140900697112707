<template>
    <overall-leaderboard :show-controls="true"></overall-leaderboard>
</template>

<script>
import OverallLeaderboard from "@/components/OverallLeaderboard.vue";
import {useSeasonStore} from "@/stores/seasonStore";
import {useSeoMeta} from "@unhead/vue";
export default {
    components: {OverallLeaderboard},
    setup() {
        const seasonStore = useSeasonStore()

        useSeoMeta({
            title: () => 'Leaderboard | Fantasy Football ' + seasonStore.season.name,
            description: () => 'View the overall table to find who is the best Fantasy Football Player in the ' + seasonStore.season.name + ' season.'
        })
    }
}
</script>
