<template>
    <div v-if="statusValue">
        <select v-model="statusValue" @change="onChange" :class="bgs[statusValue]">
            <option v-for="(statusOption, value) in statusOptions" :value="value" :disabled="statusOption.disabled">
                {{ statusOption.label }}
            </option>
        </select>
    </div>
</template>
<script>
import {computed, onMounted, ref, watch} from "vue";
import {useSeasonStore} from "@/stores/seasonStore";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['change', 'success', 'error'],
    props: {
        player: {
            type: Object,
            required: true
        },
        autoSave: {
            type: Boolean,
            required: true
        }
    },
    setup(props, {emit}) {
        const seasonStore = useSeasonStore()

        const saving = ref(false)

        const statusOptions = ref({
            active: {
                label: 'Active',
                disabled: false
            },
            inactive: {
                label: 'Inactive',
                disabled: false
            },
            new: {
                label: 'New',
                disabled: true
            },
            duplicate: {
                label: 'Duplicate',
                disabled: true
            }
        })

        const statusValue = ref(null)

        const bgs = {
            active: 'bg-green-200',
            inactive: 'bg-gray-200',
            new: 'bg-red-200',
            duplicate: 'bg-gray-200',
        }

        onMounted(() => {
            statusValue.value = props.player.status
        })

        watch(() => props.player, (newPlayer, oldPlayer) => {
            statusValue.value = newPlayer.status
        })

        const onChange = () => {
            if (props.autoSave) {
                submitForm()
            } else {
                emit('change', statusValue.value)
            }
        }


        const submitForm = () => {
            saving.value = true

            seasonStore
                .updatePlayer(props.player.id, {status: statusValue.value})
                .then(response => {
                    useFlashStore().setFlash('success', 'Player Status Updated!')
                    emit('success', response)
                })
                .catch(error =>  {
                    emit('error', error)
                    useFlashStore().axiosError(error, 'Error updating player status')
                    statusValue.value = props.player.status
                })
                .finally(() => {
                    saving.value = false
                })
        }

        return {saving, statusOptions, statusValue, bgs, onChange, submitForm}
    }
}
</script>
