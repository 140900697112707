import {defineStore} from 'pinia'

export const useMiniGameStore = defineStore('miniGameStore', {
    state: () => ({
        miniGame: undefined,
        authRanks: undefined,
    }),
    actions: {
        async init(slug, seasonId) {
            const response = await axios.get('/api/mini-games/init', {
                params: {
                    slug: slug,
                    season_id: seasonId
                }
            })
            this.miniGame = response.data.mini_game
            this.authRanks = response.data.auth_ranks
        },
        async get(id) {
            const response = await axios.get('/api/mini-league/' + id)
            this.init(...response.data)
        },
        async create(name, seasonId, admins) {
            const response = await axios.post('/api/mini-league', {
                name: name,
                season_id: seasonId,
                admins: admins
            })

            return response.data
        },
        async update(name) {
            const response = await axios.patch('/api/mini-league/' + this.miniLeague.id, {
                name: name
            })

            this.miniLeague = response.data.mini_league
        },
        async addEntry(entry, isAdmin = false) {
            const response = await axios.post('/api/mini-league/add-entry', {
                mini_league_id: this.miniLeague.id,
                entry_id: entry.id,
                is_admin: isAdmin
            })

            this.entries = _.mergeInArray(this.entries, {id: entry.id}, entry, 'append')
            this.admins = _.without(this.admins, entry.id)
            if (isAdmin) {
                this.admins.push(entry.id)
            }
        },
        async removeEntry(entry) {
            const response = await axios.post('/api/mini-league/remove-entry', {
                mini_league_id: this.miniLeague.id,
                entry_id: entry.id
            })

            this.entries = _.remove(this.entries, {id: entry.id})
            this.admins = _.without(this.admins, entry.id)
        },
    },
})
