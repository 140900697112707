<template>
    <form @submit.prevent="submitForm" class="flex">
        <input autocomplete="off" type="url" name="url" v-model="inputValue" class="" :class="inputClass" />
        <a
            v-if="storedValue"
            :href="storedValue"
            target="_blank"
            class="text-xs inline-flex items-center px-1 text-gray-900 bg-gray-200 border rounded-l-0 border-gray-300 border-e-0 rounded-r-md">
            <i class="fa-solid fa-link"></i>
        </a>
    </form>
</template>

<script>
import {computed, ref, watch} from "vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['modelUpdated'],
    props: {
        model: {
            type: Object,
            required: true
        },
        platform: {
            type: String,
            required: true
        },
        autoSave: {
            type: Boolean,
            default: true
        }
    },
    setup(props, {emit}) {
        const flashStore = useFlashStore()



        watch(() => props.model, () => {
            inputValue.value = _.get(props.model, 'links.' + props.platform + '.platform_url')
        })

        const inputValue = ref(_.get(props.model, 'links.' + props.platform + '.platform_url'))
        const storedValue = computed(() => {
            return _.get(props.model, 'links.' + props.platform + '.platform_url')
        })

        const inputClass = computed(() => {
            return {
                'rounded-r-none': storedValue.value,
                'warning': (inputValue.value || '') !== (storedValue.value || ''),
            }
        })

        const submitForm = function(event) {
            axios
                .post('/api/platform-links', {
                    model_id: props.model.model_id,
                    model_type: props.model.model_type,
                    platform: props.platform,
                    url: event.target.url.value || null
                })
                .then(response => {
                    flashStore.setFlash('success', 'Saved!')
                    emit('modelUpdated', response.data.data)
                })
                .catch(error => {
                    flashStore.axiosError(error, 'Error saving platform link')
                })
        }

        return {inputValue, storedValue, inputClass, submitForm}
    }
}
</script>
