<template>
    <div id="create-league-form" class="box form">
        <h1>Edit League</h1>
        <br />
        <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="league_name">Name</label>
                <input type="text" id="league_name"
                       placeholder="" required
                       v-model="name">
            </div>
            <div class="mb-3">
                <button type="submit" :disabled="!isValid">
                    Update League
                </button>
                <router-link :to="{name:'mini-league', params:{code:league.code}}" class="btn danger">
                    Back
                </router-link>
            </div>
        </form>
    </div>
</template>
<script>
import {computed, onMounted, ref, watch} from "vue";
import {useSeasonStore} from "../stores/seasonStore";
import miniLeagueQuery from "../queries/miniLeagueQuery.js";
import {useFlashStore} from "@/stores/flashStore";
import router from "@/routes";

export default {
    emits: ['error', 'success'],
    props: {
        league: {
            type: Object,
            required: true
        }
    },
    setup(props, {emit}) {
        const store = useSeasonStore()

        const name = ref(props.league.name)

        watch(() => props.league, (newVal) => {
            name.value = newVal.name
        })

        const isValid = computed(() => {
            return name.value.length >= '2'
        })

        const submitForm = () => {
            miniLeagueQuery
                .update(props.league.id, {name: name.value})
                .then(response => {
                    emit('success', response.data)
                    useFlashStore().setFlash('success', 'League updated!')
                    router.push({name:'mini-league', params:{code:props.league.code}})
                })
                .catch(error =>  {
                    useFlashStore().axiosError(error, 'Error updated mini league')
                })
        }

        return {name, isValid, submitForm}
    }
}
</script>
