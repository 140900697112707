export default {
    async user(seasonId, userId) {
        const response = await axios.get('/api/predictions/user/' + seasonId + '/' + userId)

        return response.data
    },
    async season(seasonId) {
        const response = await axios.get('/api/predictions/season/' + seasonId)

        return response.data
    },
    async save(payload) {
        const response = await axios.post('/api/predictions', payload)

        return response.data
    },
}
