<template>
    <div v-if="lastPage > 1" class="pagination-controls blue-gradient p-2 rounded">
        <template v-for="pageNum in lastPage">
            <button @click="$emit('goTo', pageNum)" :disabled="currentPage === pageNum">
                {{ pageNum }}
            </button>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        lastPage: {
            required: true,
            type: Number
        },
        currentPage: {
            required: true,
            type: Number
        }
    }
}
</script>

