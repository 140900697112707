<template>
    <select :value="modelValue" @change="updateValue" class="w-auto ml-3">
        <option value="overall">{{ nullText }}</option>
        <option v-for="gameweek in gameweeks" :value="gameweek.id">
            GW {{ gameweek.number }}
        </option>
    </select>
</template>
<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";

export default {
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            default: null
        },
        nullText: {
            type: String,
            default: 'Overall'
        }
    },
    setup(props, {emit}) {
        const seasonStore = useSeasonStore()

        const gameweeks = computed(() => {
            return _.sortBy(_.filter(seasonStore.gameweeks, gameweek => {
                return gameweek.status !== 'scheduled'
            }), 'number')
        })

        const updateValue = (event) => {
            emit('update:modelValue', event.target.value === 'overall' ? 'overall' : parseInt(event.target.value));
        }

        return {gameweeks, updateValue}
    }
}
</script>

