export default {
    async get(id) {
        const response = await axios.get('/api/mini-league/' + id)

        return response.data
    },
    async getLeaderboard(id, payload) {
        const response = await axios.get('/api/mini-league/' + id + '/leaderboard', {
            params: {
                month: payload.month || 'overall',
                page: payload.page,
                per_page: payload.perPage || 200,
                gameweek_sort: payload.activeGw,
                starting_gameweek_id: payload.starting_gameweek_id
            }
        })

        return response.data
    },
    async create(name, seasonId, admins) {
        const response = await axios.post('/api/mini-league', {
            name: name,
            season_id: seasonId,
            admins: admins
        })

        return response.data
    },
    async update(id, payload) {
        const response = await axios.patch('/api/mini-league/' + id, payload)

        return response.data
    },
    async join(entry, code) {
        const response = await axios.post('/api/mini-league/join', {
            code: code,
            entry_id: entry.id
        })

        return response.data
    },
    async removeEntry(entry, id) {
        const response = await axios.post('/api/mini-league/remove-entry', {
            mini_league_id: id,
            entry_id: entry.id
        })

        return response.data
    },
    async getEntries(entry) {
        const response = await axios.get('/api/mini-league/entry/' + entry.id)

        return response.data
    },
    async getUserEntries(userId) {
        const response = await axios.get('/api/mini-league/user/' + userId)

        return response.data
    },

}
