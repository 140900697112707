<template>
    <div id="mini-leagues-table">
        <h1>My Leagues</h1>
        <table v-if="leagues.length > 0">
            <thead>
            <tr>
                <th>Rank</th>
                <th>Name</th>
                <th>Admin</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="league in leagues">
                <td>
                    {{ league.pivot && league.pivot.position ? league.pivot.position : '-' }}
                </td>
                <th>
                    <router-link :to="{name:'mini-league', params:{code:league.code}}">
                        {{ league.name }}
                    </router-link>
                </th>
                <td>
                    {{ league.pivot && league.pivot.is_admin ? 'You are admin' : '' }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        leagues: {
            type: Array,
            required: true
        }
    }
}
</script>
