<template>
    <teleport to="body">
            <div ref="modal" class="modal-bg" @click="close" @keydown.esc="close" tabindex="0">
                <div class="modal-content">
                    <div  @click.stop="" >
                        <button class="modal-close" @click="close">
                            <i class="fa-regular fa-rectangle-xmark"></i>
                        </button>
                        <slot name="body">default body</slot>
                    </div>
                </div>
            </div>
    </teleport>
</template>
<script>
import {onMounted, ref} from "vue";

export default {
    emits: ['close'],
    setup(props, {emit}) {
        const close = function() {
            emit('close')
        }

        const modal = ref(null)

        onMounted(() => {
            modal.value.focus()
        })

        return {close, modal}
    }
}
</script>
