<template>
    <div id="overall-leaderboard">
        <div v-if="entry" style="margin:1rem auto;width:max-content;">Ranked <strong>#{{ entry.rank }}</strong> with <strong>{{ entry.points }} points</strong></div>
        <leaderboard
            v-if="aroundAuthEntries && leaderEntries"
            :entries="aroundAuthEntries"
            :prefix-entries="leaderEntries.data"
            :show-controls="false"
        ></leaderboard>
    </div>
</template>

<script>
import {onMounted, ref, computed, watch} from "vue";
import Leaderboard from "../components/Leaderboard.vue";
export default {
    components: {Leaderboard},
    props: {
        perPage: {
            type: Number,
            default: 20
        },
        seasonId: {
            type: Number,
            required: true
        },
        entry: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        onMounted(() => {
            axios
                .get('/api/leaderboard/entry', {
                    params: {
                        per_page: props.perPage,
                        season_id: props.seasonId,
                        entry_id: props.entry.id
                    }
                })
                .then(response => {
                    aroundAuthEntries.value = response.data
                })

            axios
                .get('/api/leaderboard', {
                    params: {
                        per_page: 3,
                        season_id: props.seasonId
                    }
                })
                .then(response => {
                    leaderEntries.value = response.data
                })
        })


        const aroundAuthEntries = ref(null)
        const leaderEntries = ref(null)

        return {aroundAuthEntries, leaderEntries}
    }
}
</script>
