<template>
    <div v-if="playerSeasons" class="box">
        <div class="flex items-center">
            <h1 class="mt-3">{{ player.name }}</h1>
            <div>
                <button class="btn danger" @click="$emit('close')">
                    Close
                </button>
            </div>
        </div>
        <player-seasons-stats
            :player-seasons="playerSeasons"
            :seasons="seasons"
        ></player-seasons-stats>
    </div>
</template>

<script>
import {ref, onMounted} from "vue";
import PlayerSeasonsStats from "./PlayerSeasonsStats.vue";

export default {
    components: {PlayerSeasonsStats},
    props: {
        playerId: {
            type: [Number, String],
            required: true
        }
    },
    emits: ['close'],
    setup(props) {
        onMounted(() => {
            axios
                .get('/api/players/' + props.playerId + '/history')
                .then(response => {
                    player.value = response.data.player
                    playerSeasons.value = response.data.player_seasons
                    seasons.value = response.data.seasons
                })
        })

        const player = ref(undefined)
        const playerSeasons = ref(undefined)
        const seasons = ref(undefined)

        return {player, playerSeasons, seasons}
    }
}
</script>
