<template>
    <transfers-list route-name="mod-player" :season-id="seasonId"></transfers-list>
</template>

<script>
import TransfersList from "@/components/TransfersList.vue";
import {useSeoMeta} from "@unhead/vue";
import {useSeasonStore} from "@/stores/seasonStore";

export default {
    components: {TransfersList},
    props: {
        seasonId: {
            type: [Number, String],
            required: true
        },
    },
    setup() {
        const store = useSeasonStore()

        useSeoMeta({
            title: () => 'Mod Transfers | ' + store.season.name,
            description: () => 'Mod transfers ' + store.season.name
        })
    }
}
</script>
