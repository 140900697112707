<template>
    <div>
        <router-link :to="{name:'season', params:{seasonId:season.id}}" class="box blue-gradient block">
            <div class="flex items-center">
                <div class="mr-3">
                    <img v-if="season.flag_path" :src="season.flag_path" />
                </div>
                <h2 style="font-size:16px;margin:0">
                    {{season.name}}
                </h2>
            </div>
            <div class="flex items-center justify-center">
                <img v-if="season.image_path" :src="season.image_path" />
            </div>
        </router-link>
        <div v-if="season.status === 'finished'" class="box red-gradient block">
            <strong style="color:yellow;">Winner:</strong> <strong v-html="season.winner_entry ? season.winner_entry.name : ''"></strong>
        </div>
        <div v-else-if="season.prize_text" class="box red-gradient block">
            <strong style="color:yellow;">Prizes:</strong> <strong v-html="season.prize_text"></strong>
        </div>
        <div v-if="startDate" class="box green-gradient block">
            <strong style="color:yellow;">First Match:</strong> {{ startDate }}
        </div>
        <template v-else-if="authEntry && authEntry.rank" >
            <div v-if="deadline" class="box red-gradient block">
                <strong style="color:yellow;">Deadline:</strong> {{ deadline }}
            </div>
            <div class="box green-gradient block">
                Your Rank <strong>#{{ authEntry.rank }}</strong>
            </div>
        </template>
    </div>
</template>

<script>
import {computed} from "vue";
import dayjs from "dayjs";

export default {
    props: {
        season: {
            type: Object,
            required: true
        },
        authEntry: {
            type: Object,
            default: undefined
        }
    },
    setup(props) {
        const startDate = computed(() => {
            const date = new dayjs(props.season.start_at)
            return date.isAfter(new dayjs())
                ? date.fromNow(true)
                : null
        })

        const deadline = computed(() => {
            if (!props.season.deadline_timestamp) {
                return null
            }
            const date = dayjs.unix(props.season.deadline_timestamp)
            return date.isAfter(new dayjs())
                ? date.fromNow(true)
                : null
        })

        return {startDate, deadline}
    }
}
</script>
