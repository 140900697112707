<template>
    <div v-if="loaded">
        <fixture-header :fixture="fixture"></fixture-header>
        <div v-if="fixture.status !== 'NS'" class="m-3">
            <button @click="goToIndex" class="btn warning">
                Go Back
            </button>
            <div id="twenty-one-standings" class="box">
                <twenty-one-leaderboard
                    :rank-value="fixture.id"
                    rank-key="fixture"
                    :with-picks="true"
                ></twenty-one-leaderboard>
            </div>
        </div>
        <template v-else>
            <div class="box">
                <p></p>
                <div v-if="fixture" class="mt-3">
                    <button v-if="picks.length === 0" disabled class="error">
                        Pick at least 1 player
                    </button>
                    <button v-else-if="saving" disabled class="loading">
                        Saving...
                    </button>
                    <button v-else @click.once="savePicks" class="success">
                        Save {{ picks.length }} Picks
                    </button>
                    <button v-if="picks.length > 0" @click="removeAllPicks" class="error">
                        Remove all picks
                    </button>
                    <button @click="goToIndex" class="error">
                        Go Back
                    </button>
                </div>
            </div>
            <fixture-player-picker
                v-if="fixture" :key="fixture.id"
                :fixture="fixture"
                v-model:picks="picks"
            ></fixture-player-picker>
        </template>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref, watch} from "vue";
import FixturePlayerPicker from "../components/FixturePlayerPicker.vue";
import FixtureHeader from "../components/FixtureHeader.vue";
import router from "../routes.js";
import TwentyOneLeaderboard from "../components/TwentyOneLeaderboard.vue";
import {useSeoMeta} from "@unhead/vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    components: {TwentyOneLeaderboard, FixtureHeader, FixturePlayerPicker},
    props: {
        fixtureId: {
            type: [Number,String],
            required: true
        }
    },
    setup(props) {
        const store = useSeasonStore()
        const fixture = computed(() => {
            return store.fixtures[props.fixtureId]
        })

        useSeoMeta({
            title: () => '21 | ' + fixture.value.description + ' | ' + store.season.name,
            description: () => "Pick your 21s for " + fixture.value.description + " in " + store.season.name + ". Twenty One is a unique blackjack style Fantasy Football Game."
        })

        const loaded = ref(false)
        const saving = ref(false)
        const picks = ref([])

        onMounted(() => {
            return axios
                .get('/api/mini-games/picks', {
                    params: {
                        mini_game_slug: 'twenty-one',
                        season_id: store.seasonId,
                        fixture_id: props.fixtureId
                    }
                })
                .then(response => {
                    picks.value = _.map(response.data.picks, 'pick_id')
                    loaded.value = true
                })
        })

        const removeAllPicks = () => {
            picks.value = []
            savePicks()
        }

        const savePicks = async () => {
            saving.value = true
            return await axios
                .post('/api/mini-games/picks', {
                    mini_game_slug: 'twenty-one',
                    season_id: fixture.value.season_id,
                    gameweek_id: fixture.value.gameweek_id,
                    fixture_id: fixture.value.id,
                    picks: _.map(picks.value, playerId => {
                        return {
                            type: 'PlayerSeason',
                            id: playerId
                        }
                    }),
                })
                .then(response => {
                    useFlashStore().setFlash('success', 'Picks Saved')

                    goToIndex()
                })
                .catch(error =>  {
                    useFlashStore().axiosError(error, 'Picked failed to save')
                })
                .finally(() => saving.value = false)
        }

        const goToIndex = () => {
            router.push({name:'twenty-one', params: {gameweekId: fixture.value.gameweek_id, test: 'foobar'}})
        }

        return {fixture, picks, loaded, saving, removeAllPicks, savePicks, goToIndex}
    }
}
</script>
