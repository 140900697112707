<template>
    <div class="pagination-controls">
        <button @click="$emit('goTo', previousGwId)" :disabled="previousDisabled">Previous</button>
        <button @click="$emit('goTo', nextGwId)" :disabled="nextDisabled">Next</button>
    </div>
</template>
<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";

export default {
    props: {
        activeGw: {
            required: true,
            type: Number
        },
        maxNumber: {
            type: Number,
            required: false
        },
        minNumber: {
            type: Number,
            required: false
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const previousGwId = computed(() => {
            return store.gameweekIds[_.indexOf(store.gameweekIds, props.activeGw) - 1]
        })

        const previousGw = computed(() => {
            return store.gameweeks[previousGwId.value]
        })

        const nextGwId = computed(() => {
            return store.gameweekIds[_.indexOf(store.gameweekIds, props.activeGw) + 1]
        })

        const nextGw = computed(() => {
            return store.gameweeks[nextGwId.value]
        })

        const previousDisabled = computed(() => {
            return !previousGw.value || (props.minNumber && previousGw.value.number < props.minNumber)
        })

        const nextDisabled = computed(() => {
            return !nextGw.value || (props.maxNumber && nextGw.value.number > props.maxNumber)
        })

        return {previousGwId, nextGwId, previousDisabled, nextDisabled}
    }
}
</script>

