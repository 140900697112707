export default {
    async ranks(miniGameSlug, key, value, withPicks = false) {
        const response = await axios.get('/api/mini-games/ranks', {
            params: {
                mini_game_slug: miniGameSlug,
                key: key,
                value: value,
                with_picks: withPicks ? 1 : 0
            }
        })

        return response.data
    },
}
