<template>
    <tr v-if="player">
        <td>
            {{ player.age }}
        </td>
        <td>
            <player-status-select
                :auto-save="true"
                :player="player"
            ></player-status-select>
        </td>
        <td>
            <div>
                <button @click.prevent="$emit('select', player)" class="btn mr-2">
                    <i class="fa-solid fa-info"></i>
                </button>
                <router-link :to="{name: 'admin-player', params: {playerId: player.id}}">
                    {{ player.name }}
                </router-link>
            </div>
            <div>
                <small>
                    <a :href="player.player.platform_url" target="_blank">
                        season_id: {{ player.id }} player_id: {{ player.player_id }}
                    </a>
                </small>
            </div>
        </td>
        <td>
            <player-position-input
                :auto-save="true"
                :player="player"
            ></player-position-input>
        </td>
        <td>
            <a href="#" @click.prevent="$emit('selectTeam', team.id)">
                {{ team.short_name }}
            </a>
        </td>
        <td>
            {{ player.value }}
        </td>
        <td>
            <form @submit.prevent="submitValueForm">
                <input type="number" v-model="newValue" style="width:60px">
            </form>
        </td>
        <td>
            {{ player.points }}
        </td>
        <td>
            <template v-if="player.selected_by_percent">
                {{ player.selected_by_percent.toFixed(2) }}%
            </template>
        </td>
        <td>
            {{ player.appearances }}
        </td>
        <td>
            {{ player.points_per_game }}
        </td>
        <td>
            {{ player.minutes }}
        </td>
        <td>
            {{ player.goals_scored }}
        </td>
        <td>
            {{ player.assists }}
        </td>
        <td>
            {{ player.clean_sheets }}
        </td>
        <td>
            <span v-html="player.notes"></span>
        </td>
        <!--
        <td>
            <a v-if="player.player.twitter_handle" target="_blank" :href="'https://twitter.com/' + player.player.twitter_handle">
                {{ player.player.twitter_handle }}
            </a>
            <br />
            <form @submit.prevent="submitTwitterForm">
                <input type="text" v-model="newTwitter">
            </form>
        </td>
        -->
    </tr>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref} from "vue";
import PlayerStatusSelect from "@/components/PlayerStatusSelect.vue";
import PlayerPositionInput from "@/components/PlayerPositionInput.vue";

export default {
    components: {PlayerPositionInput, PlayerStatusSelect},
    props: {
        playerId: {
            type: Number,
            required: true
        },
    },
    emits: ['select', 'selectTeam', 'selectPosition'],
    setup(props) {
        const store = useSeasonStore()

        const player = computed(() => {
            return store.players[props.playerId]
        })

        const team = computed(() => {
            return store.teams[player.value.team_id]
        })

        const newValue = ref('')
        const submitting = ref(false)

        const newTwitter = ref('')
        const submittingTwitter = ref(false)

        const submitValueForm = async (event) => {
            if (submitting.value) {
                return
            }

            submitting.value = true

            try {
                const response = await axios
                    .patch('/api/admin/player-seasons/' + props.playerId, {
                        value: newValue.value
                    })

                newValue.value = ''
                submitting.value = false

                store.players[props.playerId] = response.data.player_season
            } catch (e) {
                console.log(e)
                submitting.value = false
            }
        }

        const submitTwitterForm = async (event) => {
            if (submittingTwitter.value) {
                return
            }

            submittingTwitter.value = true

            try {
                const response = await axios
                    .patch('/api/admin/players/' + player.value.player_id, {
                        twitter_handle: newTwitter.value
                    })

                newTwitter.value = ''
                submittingTwitter.value = false

                store.players[props.playerId].player = response.data.player
            } catch (e) {
                console.log(e)
                submittingTwitter.value = false
            }
        }

        return {player, team, newTwitter, newValue, submitValueForm, submitTwitterForm}
    }
}
</script>
