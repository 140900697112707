<template>
    <table v-if="models" class="w-full">
        <thead>
            <tr>
                <th>
                    Name
                </th>
                <th v-for="platform in platforms">
                    {{ platform.label }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="model in models">
                <th>
                    <router-link
                        :to="{name: 'platform-links', params: {seasonId: model.season_id, modelId: model.model_id, modelType: model.type}}"
                    >
                        {{ model.name }}
                    </router-link>
                </th>
                <td v-for="platform in platforms">
                    <platform-links-input
                        :model="model"
                        :platform="platform.key"
                        @modelUpdated="modelUpdated($event)"
                    ></platform-links-input>
                </td>
            </tr>
        </tbody>
    </table>
    <div v-else>
        Loading...
    </div>
</template>

<script>
import {ref, onMounted} from "vue";
import PlatformLinksInput from "@/components/PlatformLinksInput.vue";

export default {
    components: {PlatformLinksInput},
    props: {
        seasonId: {
            type: Number,
            default: null
        },
        modelType: {
            type: String,
            default: null
        },
        modelId: {
            type: Number,
            default: null
        }
    },
    setup(props) {
        const platforms = ref([
            {
                label: 'TransferMarkt',
                key: 'transfermarkt'
            },
            {
                label: 'SofaScore',
                key: 'sofascore'
            },
            {
                label: 'FootyStats',
                key: 'footystats'
            },
            {
                label: 'WhoScored',
                key: 'whoscored'
            },
            {
                label: 'UnderStat',
                key: 'understat'
            },
            {
                label: 'FBRef',
                key: 'fbref'
            },
            {
                label: 'FotMob',
                key: 'fotmob'
            },
            {
                label: 'Soccerway',
                key: 'soccerway'
            }
        ])

        const getModels = function (payload) {
            models.value = null

            axios
                .get('/api/platform-links/', {
                    params: payload
                })
                .then(response => {
                    models.value = response.data.data
                })
        }

        onMounted(() => {
            if (!props.modelType) {
                getModels()
            } else {
                getModels({
                    model_id: props.modelId,
                    model_type: props.modelType,
                    season_id: props.seasonId
                })
            }
        })

        const models = ref(null)

        const modelUpdated = function (model) {
            models.value = _.replaceInArray(models.value, {model_id: model.model_id}, model)
        }

        return {platforms, models, modelUpdated}
    }
}
</script>
