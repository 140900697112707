<template>
    <div id="fixtures" class="box">
        <div class="flex-default">
            <div>
                <h2>GW {{ activeGw.number }} Predictions</h2>
                <span class="locked-at">Deadline: {{ deadlineText }}</span>
            </div>
            <gameweek-pagination
                v-if="showPagination"
                :active-gw="activeGwId"
                @goTo="activeGwId = $event"
            ></gameweek-pagination>
        </div>
        <div v-for="(fixtures,timestamp) in activeFixtures">
            <div class="date-header">
                <h3>{{ prettyDate(timestamp) }}</h3>
            </div>
            <div v-for="fixture in fixtures" class="flex items-center ">
                <div style="width:70px;text-align: center">
                    <span v-if="fixture.result" class="flex items-center">
                        <span class="pr-2">
                            <svg v-if="predictions[fixture.id] && fixture.result === predictions[fixture.id].result" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"/></svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M313.4 479.1c26-5.2 42.9-30.5 37.7-56.5l-2.3-11.4c-5.3-26.7-15.1-52.1-28.8-75.2H464c26.5 0 48-21.5 48-48c0-18.5-10.5-34.6-25.9-42.6C497 236.6 504 223.1 504 208c0-23.4-16.8-42.9-38.9-47.1c4.4-7.3 6.9-15.8 6.9-24.9c0-21.3-13.9-39.4-33.1-45.6c.7-3.3 1.1-6.8 1.1-10.4c0-26.5-21.5-48-48-48H294.5c-19 0-37.5 5.6-53.3 16.1L202.7 73.8C176 91.6 160 121.6 160 153.7V192v48 24.9c0 29.2 13.3 56.7 36 75l7.4 5.9c26.5 21.2 44.6 51 51.2 84.2l2.3 11.4c5.2 26 30.5 42.9 56.5 37.7zM32 384H96c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H32C14.3 96 0 110.3 0 128V352c0 17.7 14.3 32 32 32z"/></svg>
                        </span>
                        {{ fixture.home_team_score }} - {{ fixture.away_team_score }}
                    </span>
                    <template v-else>
                        {{ prettyTime(fixture.timestamp) }}
                    </template>
                </div>
                <prediction-fixture-list-row
                    :key="fixture.id"
                    :fixture="fixture"
                    :prediction="predictions[fixture.id]"
                    class="grow"
                    @savePrediction="$emit('savePrediction', $event)"
                ></prediction-fixture-list-row>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref} from "vue";
import PredictionFixtureListRow from "./PredictionFixtureListRow.vue";
import dayjs from 'dayjs';
import GameweekPagination from "./GameweekPagination.vue";

export default {
    components: {GameweekPagination, PredictionFixtureListRow},
    props: {
        showPagination: {
            type: Boolean,
            default: true
        },
        gameweekId: {
            type: Number,
            default: null
        },
        predictions: {
            type: Object,
            required: true
        }
    },
    emits: ['savePrediction'],
    setup(props) {
        const store = useSeasonStore()

        const activeGwId = ref(props.gameweekId || store.fixturesGwId || store.transfersGwId)
        const activeGw = computed(() => store.gameweeks[activeGwId.value])

        const activeFixtures = computed(() => {
            return _.groupBy(
                _.filter(store.activeFixtures, fixture => {
                    return fixture.gameweek_id === parseInt(activeGwId.value)
                }),
                (fixture) => {
                    return dayjs.unix(fixture.timestamp).startOf('day').unix()
                }
            )
        })

        const prettyDate = function(timestamp) {
            return dayjs.unix(timestamp).format('dddd MMMM D, YYYY')
        }

        const prettyTime = function(timestamp) {
            return dayjs.unix(timestamp).format('hh:mm A')
        }

        const deadlineText = computed(() => {
            return dayjs(activeGw.value.start_at).format('dddd MMMM D h:mm A')
        })

        return {activeFixtures, activeGw, activeGwId, prettyDate, prettyTime, deadlineText}
    }
}
</script>
