<template>
    <div id="mini-league-edit-page">
        <edit-league-form :league="league" @success="$emit('leagueUpdated', $event)"></edit-league-form>
    </div>
</template>

<script>
import EditLeagueForm from "@/components/EditLeagueForm.vue";

export default {
    emits: ['leagueUpdated'],
    props: {
        league: {
            type: Object,
            required: true
        }
    },
    components: {EditLeagueForm},
}
</script>
