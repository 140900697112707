<template>
    <div>
        <season-form v-if="season" @success="onSuccess" :season="season"></season-form>
        <div v-else class="box error">
            Season #{{ seasonId }} Not Found
            {{ season || 'test' }}
        </div>
    </div>
</template>

<script>
import SeasonForm from "@/components/SeasonForm.vue";
import {useRouter} from "vue-router";
import {computed} from "vue";
import {useGodStore} from "@/stores/godStore";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {SeasonForm},
    props: {
        seasonId: {
            type: [Number,String],
            required: true
        }
    },
    setup(props) {
        const router = useRouter();
        const godStore = useGodStore()

        const season = computed(() => {
            return godStore.getSeason(props.seasonId)
        })

        useSeoMeta({
            title: () => 'God | ' + season.value.name,
            description: () => 'Edit ' + season.value.name
        })

        const onSuccess = () => {
            router.push({name: 'god'})
        }

        return {onSuccess, season}
    }
}
</script>
