<template>
    <div v-if="loaded" id="team-of-the-week" class="box" style="padding:0;">
        <div class="flex-default" style="padding:0.5rem 1rem;">
            <h2>GW {{ activeGw.number }} TOTW - {{ totalPoints }} points</h2>
            <gameweek-pagination
                :active-gw="activeGwId"
                @goTo="activeGwId = $event"
            ></gameweek-pagination>
        </div>
        <div class="pitch-container">
            <div class="pitch" :key="activeGwId" style="width:100%;margin:0;">
                <div v-for="groupId in groups" class="row" :class="groupId">
                    <picker-player
                        v-for="player in groupedPlayers[groupId]"
                        :key="player.id"
                        :player="player"
                        :stats="teams[activeGwId][player.id]"
                        :selectable="false"
                        info-type="points"
                    ></picker-player>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import PickerPlayer from "./PickerPlayer.vue";
import {useTeamPickerStore} from "../stores/teamPickerStore.js";
import GameweekPagination from "./GameweekPagination.vue";

export default {
    components: {GameweekPagination, PickerPlayer},
    props: {
        gameweekId: {
            type: [Number,String],
            default: undefined
        }
    },
    setup(props) {
        const store = useSeasonStore()

        onMounted(() => {
            axios
                .get('/api/team-of-the-week', {
                    params: {
                        season_id: store.seasonId
                    }
                })
                .then(response => {
                    teams.value = response.data
                })
        })

        const activeGwId = ref(props.gameweek_id || store.pointsGwId)
        const activeGw = computed(() => store.gameweeks[activeGwId.value])
        const teams = ref({})

        const loaded = computed(() => {
            return Object.keys(teams.value).length > 0
        })

        const totalPoints = computed(() => {
            return teams.value[activeGwId.value]
                ? _.sumBy(Object.values(teams.value[activeGwId.value]), 'points')
                : 0
        })

        const groups = ['GK', 'DEF', 'MID', 'ATT']
        const groupedPlayers = computed(() => {
            return _.groupBy(
                _.pick(store.players, _.map(teams.value[activeGwId.value], 'player_season_id')),
                'position'
            )
        })

        return {loaded, activeGwId, activeGw, teams, totalPoints, groups, groupedPlayers}
    }
}
</script>
