<template>
    <div>
        <div class="m-3">
            <span class="warning-text">
                <strong>Get Started</strong>
                Just click any fixture to play Twenty One's for that particular match.
                Once you've made picks they'll be displayed below the fixture.
                You can click on a fixture that's already started to view everyone's score.
            </span>
        </div>
        <twenty-one-user-gameweek
            :user="user"
        ></twenty-one-user-gameweek>
    </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import TwentyOneUserGameweek from "./TwentyOneUserGameweek.vue";
import {useDefaultStore} from "../stores/defaultStore";

export default {
    components: {
        TwentyOneUserGameweek
    },
    props: {
        showPagination: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const store = useDefaultStore()

        const user = computed(() => {
            return store.authUser
        })

        return {user}
    }
}
</script>
