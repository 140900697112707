<template>
    <div class="flex">
        <button @click="$emit('goTo', collection.meta.current_page - 1)" :disabled="collection.meta.current_page === 1">
            Previous
        </button>
        <button @click="$emit('goTo', collection.meta.current_page + 1)" :disabled="collection.meta.per_page > collection.data.length">
            Next
        </button>
    </div>
</template>
<script>
export default {
    props: {
        collection: {
            required: true,
            type: Object
        }
    }
}
</script>

