<template>
    <div v-if="loaded" :key="activeGwId" class="grid grid-cols-1 md:grid-cols-2">
        <div>
            <div id="gw-overview" class="box">
                <div class="flex-default">
                    <div>
                        <h2>GW {{ activeGw.number }}</h2>
                    </div>
                    <gameweek-pagination
                        v-if="showPagination"
                        :active-gw="activeGwId"
                        @goTo="setGw($event)"
                    ></gameweek-pagination>
                </div>
                <div v-if="gwRank && activeGw.has_started">
                    <ul>
                        <li>
                            Rank #{{ gwRank.rank }}
                        </li>
                        <li>
                            {{ gwRank.points }} Points
                        </li>
                        <li>
                            {{ gwRank.data.fixtures || 0 }} Entries
                        </li>
                        <li>
                            {{ gwRank.data.perfects || 0 }} Twenty One's
                        </li>
                        <li>
                            {{ gwRank.data.busts || 0 }} Busts
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="showLeaderboard" id="standings" class="box">
                <twenty-one-leaderboard
                    rank-key="gameweek"
                    :rank-value="activeGwId"
                ></twenty-one-leaderboard>
            </div>
        </div>
        <div id="fixtures" class="box">
            <div v-for="(fixtures,timestamp) in activeFixtures">
                <div class="date-header">
                    <h3>{{ prettyDate(timestamp) }}</h3>
                </div>
                <twenty-one-fixture-listing
                    v-for="fixture in fixtures"
                    :key="fixture.id"
                    :fixture="fixture"
                    :picks="picks[fixture.id]"
                    :player-fixtures="playerFixtures[fixture.id]"
                    :rank="fixtureRanks[fixture.id]"
                ></twenty-one-fixture-listing>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import FixtureListRow from "./FixtureListRow.vue";
import dayjs from 'dayjs';
import GameweekPagination from "./GameweekPagination.vue";
import TwentyOneFixtureListing from "./TwentyOneFixtureListing.vue";
import TwentyOneLeaderboard from "./TwentyOneLeaderboard.vue";

export default {
    components: {TwentyOneLeaderboard, TwentyOneFixtureListing, GameweekPagination, FixtureListRow},
    props: {
        showPagination: {
            type: Boolean,
            default: true
        },
        showLeaderboard: {
            type: Boolean,
            default: true
        },
        gameweekId: {
            type: [Number, String],
            default: null
        },
        user: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const loadGameweek = function() {
            Promise.all([
                axios
                    .get('/api/mini-games/ranks', {
                        params: {
                            mini_game_slug: 'twenty-one',
                            user_id: props.user.id,
                            key: 'gameweek',
                            value: activeGwId.value,
                            inclusive: 1
                        }
                    }),
                axios
                    .get('/api/mini-games/picks', {
                        params: {
                            mini_game_slug: 'twenty-one',
                            user_id: props.user.id,
                            gameweek_id: activeGwId.value
                        }
                    })
            ]).then(response => {
                //console.log([ranks.ranks.data, picks])
                //ranks.value = ranks.ranks.data
                //picks.value = picks
                ranks.value = response[0].data.ranks.data
                picks.value = _.groupBy(response[1].data.picks, 'fixture_id')
                playerFixtures.value = _.groupBy(response[1].data.player_fixtures, 'fixture_id')
                loaded.value = true
            })
        }

        onMounted(() => {
            loadGameweek()
        })

        const activeGwId = ref(props.gameweekId || store.fixturesGwId)
        const picks = ref(undefined)
        const playerFixtures = ref(undefined)
        const ranks = ref(undefined)
        const loaded = ref(false)
        const activeGw = computed(() => store.gameweeks[activeGwId.value])

        const activeFixtures = computed(() => {
            return _.groupBy(
                _.filter(store.activeFixtures, {gameweek_id: activeGwId.value}),
                (fixture) => {
                    return dayjs.unix(fixture.timestamp).startOf('day').unix()
                }
            )
        })

        const prettyDate = function(timestamp) {
            return dayjs.unix(timestamp).format('dddd MMMM D, YYYY')
        }

        const setGw = function(gwId) {
            activeGwId.value = gwId
            loadGameweek()
        }

        const gwRank = computed(() => {
            return _.find(ranks.value, {fixture_id: null, gameweek_id: activeGwId.value})
        })

        const fixtureRanks = computed(() => {
            return _.keyBy(ranks.value, 'fixture_id')
        })

        return {loaded, picks, playerFixtures, activeFixtures, activeGw, activeGwId, prettyDate, gwRank, fixtureRanks, setGw}
    }
}
</script>
