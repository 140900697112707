<template>
    <div v-if="user">
        <div class="flex items-center">
            <h1 style="font-size: 28px; padding: 0px 1rem; margin:0;">{{ user.name }}</h1>
            <div>
                <router-link :to="{name: 'twenty-one'}" class="btn success">
                    Go Back
                </router-link>
            </div>
        </div>
        <twenty-one-user-gameweek
            :user="user"
            :show-leaderboard="false"
        ></twenty-one-user-gameweek>
    </div>
</template>

<script>
import TwentyOneUserGameweek from "../components/TwentyOneUserGameweek.vue";
import {onMounted, ref} from "vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {
        TwentyOneUserGameweek
    },
    props: {
        userId: {
            type: [Number, String],
            required: true
        }
    },
    setup(props) {
        onMounted(() => {
            axios
                .get('/api/users/' + props.userId)
                .then(response => {
                    user.value = response.data.user
                })
        })

        const user = ref(undefined)

        useSeoMeta({
            title: () => user.value ? user.value.name + ' | 21 | Fantasy Twenty One' : '',
            description: () => user.value ? "Twenty One Points for " + user.value.name : ''
        })

        return {user}
    }
}
</script>
