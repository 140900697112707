import {defineStore} from 'pinia'
import {computed, toRaw} from "vue";
import {useSeasonStore} from "./seasonStore";

export const useTeamPickerStore = defineStore('teamPickerStore', {
    state: () => ({
        loaded: false,
        entryId: undefined,
        gameweek: undefined,
        originals: {
            starters: [],
            subs: [],
            captainId: null,
            viceCaptainId: null,
        },
        sortOrder: {},
        starters: [],
        subs: [],
        chipId: null,
        seasonChipId: null,
        chipOptionId: null,
        captainId: null,
        viceCaptainId: null,
        selectedPlayerId: null,
        maxStarters: 11,
        maxSubs: 5,
        maxPositions: {
            'GK': 1,
            'DEF': 5,
            'MID': 5,
            'ATT': 3,
        },
        minPositions: {
            'GK': 1,
            'DEF': 3,
            'MID': 2,
            'ATT': 1,
        },
        unlimitedFreeTransfers: false,
        canUseChips: false,
    }),
    getters: {
        hasChanged: (state) => {
            return !(
                state.captainId === state.originals.captainId
                && state.viceCaptainId === state.originals.viceCaptainId
                && [...state.starters].toString() === [...state.originals.starters].toString()
                && [...state.subs].toString() === [...state.originals.subs].toString()
                && state.seasonChipId === state.originals.seasonChipId
                && state.chipOptionId === state.originals.chipOptionId
            )
        },
        ids: (state) => [...state.starters, ...state.subs],
        players: (state) => {
            return _.keyBy(_.map(state.ids, (id) => useSeasonStore().players[id]), 'id')
        },
        positionCounts: (state) => {
            return _.mapValues(_.groupBy(_.pick(state.players, state.starters), 'position'), group => group.length)
        },
        requiredPositionCounts: (state) => {
            return _.mapValues(state.minPositions, (min, position) => {
                return min - (state.positionCounts[position] || 0)
            })
        },
        isValidSquadSize: (state) => {
            return Object.values(state.players).length === 15
        },
        hasValidationErrors: (state) => {
            return state.validationErrors.length > 0
        },
        validationErrors: (state) => {
            return _.filter([
                state.captainId ? null : 'You must select a captain',
                state.viceCaptainId ? null : 'You must select a vice captain',
                state.captainId === state.viceCaptainId ? 'You cannot select the same player as captain and vice captain' : null,
                state.captainId && !state.starters.includes(state.captainId) ? 'Your captain must be a starter' : null,
                state.viceCaptainId && !state.starters.includes(state.viceCaptainId) ? 'Your vice captain must be a starter' : null,
                state.requiredPositionCounts['GK'] > 0 ? 'You must start with at least ' + state.minPositions['GK'] + ' GK' : null,
                state.requiredPositionCounts['DEF'] > 0 ? 'You must start with at least ' + state.minPositions['DEF'] + ' DEF' : null,
                state.requiredPositionCounts['MID'] > 0 ? 'You must start with at least ' + state.minPositions['MID'] + ' MID' : null,
                state.requiredPositionCounts['ATT'] > 0 ? 'You must start with at least ' + state.minPositions['ATT'] + ' ATT' : null,
                state.maxPositions['GK'] < state.positionCounts['GK'] ? 'You cannot have more than ' + state.maxPositions['GK'] + ' GK' : null,
                state.maxPositions['DEF'] < state.positionCounts['DEF'] ? 'You cannot have more than ' + state.maxPositions['DEF'] + ' DEF' : null,
                state.maxPositions['MID'] < state.positionCounts['MID'] ? 'You cannot have more than ' + state.maxPositions['MID'] + ' MID' : null,
                state.maxPositions['ATT'] < state.positionCounts['ATT'] ? 'You cannot have more than ' + state.maxPositions['ATT'] + ' ATT' : null,
            ])
        },
        activeChip: state => {
            return state.seasonChipId
                ? _.find(useSeasonStore().chips, {id: state.seasonChipId})
                : null
        },
        activeChipOption: state => {
            return state.activeChip && state.chipOptionId
                ? _.find(state.activeChip.options, {id: state.chipOptionId})
                : null
        }
    },
    actions: {
        async init(entryId, gameweek) {
            this.entryId = entryId
            this.gameweek = gameweek
            try {
                const response = await axios.get('/api/entry/' + entryId + '/gameweek/' + gameweek.id)

                useSeasonStore().setAuthEntry(response.data.entry)

                const picks = response.data.picks

                this.originals = {
                    starters: _.map(_.filter(picks, 'is_starter'), 'player_season_id'),
                    subs: _.map(_.filter(picks, 'is_sub'), 'player_season_id'),
                    captainId: _.get(_.find(picks, {is_captain: true}), 'player_season_id'),
                    viceCaptainId: _.get(_.find(picks, {is_vice_captain: true}), 'player_season_id'),
                    chipId: response.data.chip_id,
                    seasonChipId: response.data.season_chip_id,
                    chipOptionId: response.data.chip_option_id
                }
                this.sortOrder = _.pluck(picks, 'position', 'player_season_id')
                this.starters = _.clone(this.originals.starters)
                this.subs = _.clone(this.originals.subs)
                this.captainId = _.clone(this.originals.captainId)
                this.viceCaptainId = _.clone(this.originals.viceCaptainId)
                this.unlimitedFreeTransfers = response.data.unlimited_free_transfers
                this.canUseChips = response.data.can_use_chips
                this.chipId = response.data.chip_id
                this.seasonChipId = response.data.season_chip_id
                this.chipOptionId = response.data.chip_option_id

                this.loaded = true
            } catch (error) {
                console.log(error)
            }
        },
        canAddPosition(position) {
            return !this.positionCounts.hasOwnProperty(position) || this.positionCounts[position] < this.maxPositions[position]
        },
        swapPlayers(firstId, secondId) {
            this.starters = _.map(this.starters, (id) => id === firstId ? secondId : (id === secondId ? firstId : id))
            this.subs = _.map(this.subs, (id) => id === firstId ? secondId : (id === secondId ? firstId : id));

            if (!this.ids.includes(this.captainId)) {
                this.captainId = null
            }

            if (!this.ids.includes(this.viceCaptainId)) {
                this.viceCaptainId = null
            }
        },
        selectPlayer(newPlayerId) {
            const newPlayer = useSeasonStore().players[newPlayerId]
            console.log(newPlayer.name + ' SELECTED')
            if (this.selectedPlayerId === newPlayerId) {
                // Selecting already selected player, so just deselect
                this.selectedPlayerId = null
            } else if (!this.selectedPlayerId) {
                console.log('No other player selected so set them as selected')
                this.selectedPlayerId = newPlayerId
            } else if (this.starters.includes(newPlayerId) && this.starters.includes(this.selectedPlayerId)) {
                // Selected two players from starters so just change selected player
                this.swapPlayers(newPlayerId, this.selectedPlayerId)
                this.selectedPlayerId = null
            } else if (this.subs.includes(newPlayerId) && this.subs.includes(this.selectedPlayerId)) {
                // Selected two players from subs so swap them
                this.swapPlayers(newPlayerId, this.selectedPlayerId)
                this.selectedPlayerId = null
            } else if (this.starters.includes(newPlayerId) && this.subs.includes(this.selectedPlayerId)) {
                // Swapping starter with sub
                this.swapPlayers(newPlayerId, this.selectedPlayerId)
                this.selectedPlayerId = null
            } else if (this.subs.includes(newPlayerId) && this.starters.includes(this.selectedPlayerId)) {
                // Swapping sub with starter
                this.swapPlayers(newPlayerId, this.selectedPlayerId)
                this.selectedPlayerId = null
            } else {
                this.selectedPlayerId = newPlayerId
                console.error('Unable to handle selected player.')
            }
        },
        async savePicks() {
            const response = await axios.post('/api/pick-team', {
                entry_id: this.entryId,
                gameweek_id: this.gameweek.id,
                starter_ids: this.starters,
                sub_ids: this.subs,
                captain_id: this.captainId,
                vice_id: this.viceCaptainId,
                season_chip_id: this.seasonChipId,
                chip_id: this.chipId,
                chip_option_id: this.chipOptionId
            })

            await this.init(this.entryId, this.gameweek)
            return false
        },
    },
})
