<template>
    <div id="standings" class="box">
        <div v-if="loaded">
            <div class="flex-default">
                <div>
                    <h2 v-if="showingPrevious">Previous Stats</h2>
                    <h2 v-else-if="activeGroupsLeagueId === null && hasGroups">Overall</h2>
                    <h2 v-else-if="activeGroupsLeagueId === null">Standings</h2>
                    <h2 v-else-if="activeGroupsLeagueId">
                        {{ leagues.length > 1 ? activeGroupsLeague.short_name : 'Groups' }}
                    </h2>
                </div>
                <div v-if="hasGroups" class="pagination-controls">
                    <button @click="activeGroupsLeagueId = null" :disabled="activeGroupsLeagueId === null">Overall</button>
                    <template v-for="league in leagues">
                        <button @click="activeGroupsLeagueId = league.id" :disabled="activeGroupsLeagueId === league.id">
                            {{ leagues.length > 1 ? league.short_name : 'Groups' }}
                        </button>
                    </template>
                </div>
            </div>
            <div v-if="activeGroupsLeagueId">
                <template v-for="(group) in activeGroups">
                    <table class="mb-3">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th style="text-align: left">Group {{ group.name }}</th>
                            <th>P</th>
                            <th>W</th>
                            <th>D</th>
                            <th>L</th>
                            <th>GD</th>
                            <th>+/-</th>
                            <th>PTS</th>
                            <th>PPG</th>
                        </tr>
                        </thead>
                        <tbody>
                        <standing-row
                            v-for="(row, index) in group.team_stats"
                            :key="row.team_id"
                            :row="row"
                            :position="index+1"
                        ></standing-row>
                        </tbody>
                    </table>
                </template>
            </div>
            <div v-else>
                <table>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th style="text-align: left">Team</th>
                        <th>P</th>
                        <th>W</th>
                        <th>D</th>
                        <th>L</th>
                        <th>GD</th>
                        <th>+/-</th>
                        <th>PTS</th>
                        <th>PPG</th>
                    </tr>
                    </thead>
                    <tbody>
                    <standing-row
                        v-for="(row, index) in teamSeasons"
                        :key="row.team_id"
                        :row="row"
                        :position="index+1"
                    ></standing-row>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {ref, onMounted, computed} from "vue";
import StandingRow from "./StandingRow.vue";
import GameweekPagination from "./GameweekPagination.vue";

export default {
    components: {GameweekPagination, StandingRow},
    setup() {
        const store = useSeasonStore()
        const loaded = ref(false)
        const activeGroupsLeagueId = ref(null)
        const activeGroupsLeague = computed(() => _.find(leagues.value, {id: activeGroupsLeagueId.value}))
        const teamSeasons = ref(undefined);
        const leagues = ref(undefined);
        const groups = ref(undefined);
        const showingPrevious = computed(() => !!store.usePreviousSeasonId)
        const hasGroups = computed(() => !store.usePreviousSeasonId && _.size(groups.value) > 0)
        const activeGroups = computed(() => _.get(groups.value, activeGroupsLeagueId.value));

        onMounted(() => {
            getStandings()
        })

        const getStandings = () => {
            axios
                .get('/api/standings', {
                    params: {
                        season_id: store.usePreviousSeasonId || store.seasonId,
                    }
                })
                .then(response => {
                    teamSeasons.value = _.filter(response.data.team_seasons, teamSeason => {
                        return store.teams.hasOwnProperty(teamSeason.team_id)
                    })
                    leagues.value = response.data.leagues
                    groups.value = response.data.groups
                    loaded.value = true
                })
        }

        return {loaded, activeGroupsLeagueId, activeGroupsLeague, teamSeasons, leagues, groups, showingPrevious, hasGroups, activeGroups}
    }
}
</script>
