<template>
    <div id="overall-leaderboard">
        <div v-if="authEntry" style="margin:1rem auto;width:max-content;">You are ranked <strong>#{{ authEntry.rank }}</strong> with <strong>{{ authEntry.points }} points</strong></div>
        <leaderboard
            :entries="entries"
            @getPage="getPage($event)"
            :show-controls="showControls"
        ></leaderboard>
    </div>
</template>

<script>
import {onMounted, ref, computed, watch} from "vue";
import Leaderboard from "../components/Leaderboard.vue";
import {useSeasonStore} from "../stores/seasonStore.js";
export default {
    components: {Leaderboard},
    props: {
        perPage: {
            type: Number,
            default: 200
        },
        showControls: {
            type: Boolean,
            require: true
        }
    },
    setup(props) {
        const seasonStore = useSeasonStore()
        const getPage = function(payload)
        {
            entries.value = null
            axios
                .get(!payload.month || payload.month === 'overall' ? '/api/leaderboard' : '/api/leaderboard/monthly', {
                    params: {
                        per_page: props.perPage,
                        season_id: seasonStore.seasonId,
                        month: payload.month || 'overall',
                        page: payload.page,
                        gameweek_sort: payload.activeGw,
                        starting_gameweek_id: payload.starting_gameweek_id
                    }
                })
                .then(response => {
                    entries.value = response.data
                })
        }

        onMounted(() => {
            getPage({page:1})
        })


        const entries = ref(null)

        const authEntry = computed(() => {
            return seasonStore.authEntry
        })

        return {entries, authEntry, getPage}
    }
}
</script>
