<template>
    <div class="paragraphs">
        <h1 style="font-size:3rem">What are Chips?</h1>
        <p>Each week you can choose to play one "Chip", this chip will let you score extra bonus points or make extra transfers.</p>
        <p>Each chip can only be played once, however multiple versions of each chip are available throughout the seasons.</p>
        <p>Some chips can only be played between certain dates. Exactly what chips are available and when is available in the <router-link :to="{name: 'team'}">Team Page</router-link>.</p>
        <p>You can turn a chip on or off or change which chip you are using throughout the gameweek.</p>
        <p>The following chips are available:</p>
        <div v-for="chip in chips" class="mb-3">
            <h2 style="font-size:2rem">{{ chip.title }}</h2>
            <p v-if="chip.available_text"><strong>{{ chip.available_text }}</strong></p>
            <div v-html="chip.description"></div>
        </div>
    </div>
</template>
<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import ExplanationPrice from "./ExplanationPrice.vue";
import SubsText from "@/components/SubsText.vue";
export default {
    components: {SubsText, ExplanationPrice},
    setup() {
        const store = useSeasonStore()

        const season = computed(() => {
            return store.season
        })

        const chips = computed(() => {
            return store.chips
        })

        return {season, chips}
    }
}
</script>
