<template>
    <div id="feedback-form" class="form box">
        <h1>Reset Password</h1>
        <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="register_email">Your email</label>
                <input type="email" id="register_email"
                       placeholder="" required
                       v-model="email">
            </div>
            <div class="mb-3">
                <label for="register_password">Your
                    password</label>
                <input type="password" id="register_password"
                       min="6"
                       required
                       v-model="password">
            </div>
            <div class="mb-3">
                <label for="register_password_confirmation">Repeat password</label>
                <input type="password" id="register_password_confirmation"
                       min="6"
                       required
                       v-model="password_confirmation">
            </div>
            <div class="mb-3">
                <button :disabled="!isValid">
                    Set New Password
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {useDefaultStore} from "../stores/defaultStore";
import {useSeoMeta} from "@unhead/vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    props: {
        token: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const store = useDefaultStore()

        useSeoMeta({
            title: () => 'Reset Password',
            description: () => 'Lost your password? Find out how to access your locked account.'
        })

        const email = ref('')
        const password = ref('')
        const password_confirmation = ref('')

        const isValid = computed(() => {
            return password.value
                && password.value.length >= 6
                && password.value === password_confirmation.value
        })

        const submitForm = () => {
            axios
                .post('/api/auth/reset-password', {
                    token: props.token,
                    email: email.value,
                    password: password.value,
                    password_confirmation: password_confirmation.value
                })
                .then(response => {
                    useFlashStore().setFlash('success', 'Password Updated!')

                    location.replace('/')
                })
                .catch(error =>  {
                    useFlashStore().axiosError(error, 'Error resetting password')
                });
        }

        return {isValid, name, email, password, password_confirmation, submitForm}
    }
}
</script>
