<template>
    <div class="paragraphs">
        <template v-if="season && season.budget">
            <h1 style="font-size:3rem">How do players change in price?</h1>
            <p>If you've played FPL (Fantasy Premier League) before you'll know that a big part of managing your fantasy team is managing the value of your players.</p>
            <p>Players can go up and down in price throughout the season based on how much they're transferred in and out of people's teams and their recent form.</p>
            <p>The same is true in Fantasy {{ season.name }}.</p>
            <p>Starting from Gameweek 2 the price of players will change each day.</p>
            <p>A player can only increase or decrease by 0.1 each day.</p>
            <p>And a player can only change a maximum of three times each gameweek.</p>
            <p>Whether or not a player is going to rise or fall in price is based on a complex algorithm combining  transfers in and out, how much their percentage ownership changes and the points they've scored.</p>
            <p>If a player is owned by alot of people it will take more transfers in and out to require a price change than if they are not owned by very many.</p>
            <p>There should usually be less than 20 players changing price per day out of 350 that are active in the game right now.</p>
            <p>When a player rises in price you can now sell them for more than you bought them for however you only make half the profit e.g:</p>
            <p>If you buy a player for 10.5 and they rise to 10.7 when you sell you will only get 10.6 (half the profit of 0.2)</p>
            <p>If a player doesn't rise twice e.g. from 10.5 to 10.6 you will still only be able to sell at 10.5</p>
            <p>However when you sell a player who has decreased in price you always have to take the whole loss</p>
            <p>e.g if you buy a player for 10.5 and they drop to 10.4 you will only get 10.4 back when selling. If they drop to 10.3 you will only get 10.3 and so on.</p>
            <p>This will affect how much money you have to make transfers in the future. If there are two similar players priced at 9 at the start of the game and one is scoring points and the other isn't then you may find one player drops to 8.8 and the other rises to 9.2. If you made the wrong decision and picked the under performing player you might have to settle for replacing them with a player valued at 8.5 if you can't afford the player that's now 9.2.</p>
            <p>If that all sounds confusing don't worry you don't really have control over it since players prices change depending on other people's actions rather than your own.</p>
            <p>The best strategy is to focus on which players you think will score points not who you think will change in price.</p>
        </template>
    </div>
</template>
<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
export default {
    setup() {
        const store = useSeasonStore()

        const season = computed(() => {
            return store.season
        })

        return {season}
    }
}
</script>
