<template>
    <div v-if="homeTeam && awayTeam" class="fixture-prediction grid grid-cols-3">
        <button class="home-team" :class="{active:predictedResult === 'home_win'}"  @click="savePrediction('home_win')" :disabled="fixture.status !== 'NS'">
            <img v-if="homeTeam.image_path" :src="homeTeam.image_path" />
            {{ homeTeam.name }}
        </button>

        <button class="draw" :class="{active:predictedResult === 'draw'}" @click="savePrediction('draw')" :disabled="fixture.status !== 'NS'">
            Draw
        </button>

        <button class="away-team" :class="{active:predictedResult === 'away_win'}" @click="savePrediction('away_win')" :disabled="fixture.status !== 'NS'">
            <img v-if="awayTeam.image_path" :src="awayTeam.image_path" />
            {{ awayTeam.name }}
        </button>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref} from "vue";
import dayjs from 'dayjs';
import predictionsQuery from "../queries/predictionsQuery.js";

export default {
    props: {
        fixture: {
            type: Object,
            required: true
        },
        prediction: {
            type: Object,
            required: false
        }
    },
    emits: ['savePrediction'],
    setup(props, {emit}) {
        const store = useSeasonStore()

        const homeTeam = computed(() => {
            return store.teams[props.fixture.home_team_id]
        })

        const awayTeam = computed(() => {
            return store.teams[props.fixture.away_team_id]
        })

        const predictedResult = computed(() => {
            return props.prediction ? props.prediction.result : null
        })

        const savePrediction = (result) => {
            emit('savePrediction', {
                fixture_id: props.fixture.id,
                result: result
            })
        }

        return {homeTeam, awayTeam, predictedResult, savePrediction}
    }
}
</script>
