<template>
    <div id="player-merge-form" class="box form" :class="hasPossibleMatches ? 'red-gradient' : 'blue-gradient'">
        <h1>Merge</h1>

        <div v-if="player.status === 'duplicate'" class="box error">
            WARNING: {{ player.name }} is already marked as a duplicate
        </div>

        <div v-if="hasPossibleMatches" class="my-3">
            <p>The following players have appeared as possible duplicates of {{ player.name }}.</p>
            <admin-player-table
                :players="possibleMatches"
                :columns="[
                    'merge_button',
                    'similarity',
                    'date_of_birth',
                    'player_id',
                    'player_season_id',
                    'name',
                    'team',
                    'status',
                    'mod_status',
                    'selected_by',
                    'position',
                    'value',
                    'points',
                    'urls',
                    'created_at',
                ]"
                @merge="mergePlayer($event)"
            ></admin-player-table>
        </div>

        <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="other_player">Other Player</label>
                <select id="other_player" v-model="otherPlayerId">
                    <option v-for="playerOption in otherPlayers" :value="playerOption.id" :key="playerOption.id">
                        {{ playerOption.name }}
                        - {{ playerOption.similarity }}
                        - {{ playerOption.date_of_birth }}
                        - {{ playerOption.position }}
                        - {{ playerOption.value }}
                    </option>
                </select>
            </div>
            <template v-if="otherPlayer">
                <p>
                    This will remove {{ player.name }} #{{ player.id }} and keep {{ otherPlayer.name }} #{{ otherPlayer.id }}.
                </p>
                <div class="my-3">
                    <button :disabled="!otherPlayerId || saving" class="success">
                        {{ saving ? 'Saving...' : 'Merge' }}
                    </button>
                </div>
            </template>
        </form>
    </div>
</template>
<script>
import {computed, ref} from "vue";
import {useSeasonStore} from "@/stores/seasonStore";
import AdminPlayerTable from "@/components/AdminPlayerTable.vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    components: {AdminPlayerTable},
    emits: ['success', 'error'],
    props: {
        player: {
            type: Object,
            required: true
        }
    },
    setup(props, {emit}) {
        const seasonStore = useSeasonStore()

        const saving = ref(false)

        const sortBy = ref('similarity')

        const otherPlayerId = ref(null)

        const otherPlayer = computed(() => {
            return seasonStore.players[otherPlayerId.value]
        })

        const otherPlayers = computed(() => {
            return _.orderBy(
                _.map(Object.values(seasonStore.players).filter(player => player.id !== props.player.id && player.team_id === props.player.team_id), player => {
                    return {
                        ...player,
                        similarity: _.similarity(props.player.name, player.name).toFixed(2),
                        dob_match: player.date_of_birth && player.date_of_birth === props.player.date_of_birth ? 1 : 0
                    }
                }),
                'name',
                'asc'
            )
        })

        const possibleMatches = computed(() => {
            return _.orderBy(
                _.filter(otherPlayers.value, player => {
                    return player.similarity > 0.5 || player.dob_match
                }),
                ['dob_match', 'similarity'],
                ['desc', 'desc']
            )
        })

        const hasPossibleMatches = computed(() => {
            return _.size(possibleMatches.value) > 0
        })

        const submitForm = () => {
            if (otherPlayer.value) {
                mergePlayer(otherPlayer.value)
            } else {
                emit('error', 'No player selected')
            }
        }

        const mergePlayer = (otherPlayer) => {
            saving.value = true

            seasonStore
                .mergePlayer(props.player.player_id, otherPlayer.player_id)
                .then(response => {
                    useFlashStore().setFlash('success', 'Player Merged')
                    emit('success', response)
                })
                .catch(error => {
                    useFlashStore().axiosError(error, 'Error Merging Players')
                    emit('success', error)
                })
                .finally(() => {
                    saving.value = false
                })
        }

        return {saving, sortBy, otherPlayer, otherPlayerId, otherPlayers, hasPossibleMatches, possibleMatches, submitForm, mergePlayer}
    }
}
</script>
