<template>
    <div id="mini-league-page" class="text-center">
        <div class="flex items-center justify-center">
            <h1>{{ league.name }}</h1>
            <router-link v-if="isAdmin" class="btn" :to="{name: 'mini-league-edit'}">
                Edit
            </router-link>
            <button v-else-if="isMember" class="btn danger" @click.prevent="authLeave">
                Leave
            </button>
        </div>
        <div class="paragraphs m-bottom">
            <p>To invite people to this league give them this code: <strong>{{ league.code }}</strong></p>
            <p>Alternatively share this direct link to the league: <strong>{{ league.url }}</strong></p>
        </div>
        <div v-if="!authEntry" class="p-3">
            <p>You must create a team before you can join a mini league.</p>
            <div>
                <router-link :to="{name: 'transfers'}" class="call-to-action">
                    Create Team
                </router-link>
            </div>
        </div>
        <div v-else-if="!isMember">
            <p class="my-3">You are not part of this league. Would you like to join?</p>

            <div class="my-3">
                <button @click.once="join" class="call-to-action">
                    Join League
                </button>
            </div>
        </div>
        <div v-else-if="league.auth_entry">
            <div style="margin:1rem auto;width:max-content;">You are ranked <strong>#{{ league.auth_entry.position }}</strong> with <strong>{{ authEntry.points }} points</strong></div>
        </div>

        <leaderboard
            :entries="entries"
            :show-admin="isAdmin"
            :show-controls="true"
            @removeEntry="removeEntry"
            @getPage="getPage"
        ></leaderboard>
    </div>
</template>

<script>
import {onMounted, ref, computed} from "vue";
import {useSeasonStore} from "../stores/seasonStore.js";
import miniLeagueQuery from "../queries/miniLeagueQuery.js";
import Leaderboard from "../components/Leaderboard.vue";
import {useSeoMeta} from "@unhead/vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['joined', 'leagueUpdated', 'removedEntry'],
    props: {
        league: {
            type: Object,
            required: true
        }
    },
    components: {Leaderboard},
    setup(props, {emit}) {
        const store = useSeasonStore()
        const flashStore = useFlashStore()

        useSeoMeta({
            title: () => props.league.name + ' | Mini Leagues | Fantasy ' + store.season.name,
            description: () => 'Mini league for ' + store.season.name + ' season.'
        })

        onMounted(() => {
            getPage({page:1})
        })

        const getPage = (payload) => {
            entries.value = undefined
            miniLeagueQuery.getLeaderboard(props.league.code, payload)
                .then(response => {
                    entries.value = response
                })
                .catch(error => {
                    flashStore.setFlash('error', 'Failed to load leaderboard :(')
                })
        }


        const authEntry = computed(() => {
            return store.authEntry
        })
        const entries = ref(undefined)

        const isMember = computed(() => {
            return props.league.auth_entry
        })

        const isAdmin = computed(() => {
            return _.get(props.league.auth_entry, 'is_admin', false)
        })

        const join = () => {
            miniLeagueQuery.join(store.authEntry, props.league.code)
                .then(response => {
                    flashStore.setFlash('success', 'Joined League!')

                    emit('joined')

                    emit('leagueUpdated', response.data)
                })
                .catch(error =>  {
                    flashStore.setFlash('error', _.get(error, 'response.data.message', 'Join failed :('))
                })
        }

        const authLeave = () => {
            removeEntry(authEntry.value)

            emit('leagueUpdated', {
                ...props.league,
                auth_entry: null
            })
        };

        const removeEntry = (entry) => {
            miniLeagueQuery.removeEntry(entry, props.league.id)
                .then(response => {
                    flashStore.setFlash('success', 'Entry Removed!')

                    emit('removedEntry', entry)

                    getPage({page:1})
                })
                .catch(error =>  {
                    flashStore.setFlash('error', _.get(error, 'response.data.message', 'Administration failed :('))
                })
        }

        return {authEntry, entries, isMember, isAdmin, join, getPage, authLeave, removeEntry}
    }
}
</script>
