<template>
    <Transition name="slide-fade">
        <div v-if="show" class="flash-message" :class="state" @click="close">
            <slot></slot>
        </div>
    </Transition>
</template>
<script>
import {onMounted, ref, nextTick} from "vue";

export default {
    emits: ['close'],
    props: {
        duration: {
            type: Number,
            default: undefined
        },
        state: {
            type: String,
            default: 'info'
        }
    },
    setup(props, {emit}) {
        onMounted(() => {
            setTimeout(function(){
                nextTick(() => show.value = true)
            },0)

            let duration = parseInt(props.duration ?? defaultDuration[props.state])

            if (duration > 0) {
                setTimeout(() => {
                    show.value = false
                    nextTick(() => emit('close'))
                }, duration)
            }
        })

        const defaultDuration = {
            success: 3000,
            info: 3000,
            error: null
        }

        const show = ref(false)
        const close = () => {
            show.value = false
            nextTick(() => emit('close'))
        }

        return {show, close}
    }
}
</script>
<style>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>
