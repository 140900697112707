<template>
    <div id="register-form" class="box form">
        <h1>Register</h1>
        <form @submit.prevent="submitRegisterForm">
            <div class="mb-3">
                <label for="register_name">User Name</label>
                <input type="text" id="register_name"
                       placeholder="" required
                       v-model="name">
                <small>You do not need to use your real name if you don't want to. Generic usernames are okay.</small>
            </div>

            <div class="mb-3">
                <label for="register_email">Your email</label>
                <input type="email" id="register_email"
                       placeholder="" required
                        v-model="email">
            </div>
            <div class="mb-3">
                <label for="register_password">Your
                    password</label>
                <input type="password" id="register_password"
                       min="6"
                       required
                       v-model="password">
            </div>
            <div class="mb-3">
                <label for="register_password_confirmation">Repeat password</label>
                <input type="password" id="register_password_confirmation"
                       min="6"
                       required
                       v-model="password_confirmation">
            </div>
            <div class="mb-3">
                <button type="submit">
                    Register
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import {computed, ref} from "vue";
import FormButton from "../elements/FormButton.vue";
import {useDefaultStore} from "../stores/defaultStore.js";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['authenticated', 'error'],
    components: {FormButton},
    setup(props, {emit}) {
        const store = useDefaultStore()

        const name = ref('')
        const email = ref('')
        const password = ref('')
        const password_confirmation = ref('')

        const isValid = computed(() => {
            return email.value
                && password.value
                && password.value.length >= 6
                && email.value.includes('@')
                && email.value.includes('.')
                && password.value === password_confirmation.value
        })

        const submitRegisterForm = () => {
            if (password.value === password_confirmation.value) {
                store.register(name.value, email.value, password.value, {
                    browser: navigator.userAgent,
                    window_width: window.innerWidth,
                    window_height: window.innerHeight,
                    language: navigator.language || navigator.userLanguage,
                    languages: navigator.languages,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                })
                    .then(response => {
                        useFlashStore().setFlash('success', 'Registration successful!')
                        emit('authenticated', response)
                    })
                    .catch(error =>  {
                        useFlashStore().axiosError(error, 'Error registering account')
                        emit('error', error)
                    })
            } else {
                emit('error', 'Passwords Do Not Match')
            }
        }

        return {name, email, password, password_confirmation, submitRegisterForm}
    }
}
</script>
