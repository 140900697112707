<template>
    <div v-if="loaded">
        <modal v-if="modalPlayer" @close="closeModal">
            <template #body>
                <div id="player-modal">
                    <player-profile :player="modalPlayer"></player-profile>
                </div>
            </template>
        </modal>
        <season-nav-bar></season-nav-bar>
        <div v-if="season && season.status !== 'active'" class="box error">
            This season has finished. Visit the <router-link to="/"><strong style="text-decoration: underline">Home Page</strong></router-link> to see what competitions are currently active.
        </div>

        <router-view :key="seasonId"></router-view>
    </div>
</template>

<script>
import {onMounted, onUnmounted, computed} from 'vue'
import {useSeasonStore} from "../stores/seasonStore";
import NavBar from "../components/NavBar.vue";
import SeasonNavBar from "../components/SeasonNavBar.vue";
import {useFlashStore} from "@/stores/flashStore";
import Modal from "@/components/Modal.vue";
import PlayerProfile from "@/components/PlayerProfile.vue";

export default {
    components: {SeasonNavBar, NavBar, Modal, PlayerProfile},
    props: {
        seasonId: {
            type: [String, Number],
            required: true
        }
    },
    setup(props) {
        const seasonStore = useSeasonStore()

        const canMod = computed(() => seasonStore.authCanMod)

        const transfersGameweek = computed(() => seasonStore.transfersGameweek)

        const calculateTimeLeft = () => {
            if (transfersGameweek.value) {
                const timeLeftInSeconds = transfersGameweek.value.locked_at_timestamp - Math.floor(Date.now() / 1000);
                const timeLeftInMinutes = Math.floor(timeLeftInSeconds / 60);

                if (timeLeftInMinutes <= 60) {
                    useFlashStore().setCountdown(transfersGameweek.value.locked_at_timestamp, 'Deadline');
                }
            }
        }

        // Recalculate every minute
        const interval = setInterval(calculateTimeLeft, 1000*60*5);

        onMounted(() => {
            seasonStore
                .init(props.seasonId)
                .then(() => {
                    calculateTimeLeft()
                })
        })

        onUnmounted(() => {
            useFlashStore().clearCountdown()
            clearInterval(interval);
        });

        const season = computed(() => seasonStore.season)

        const loaded = computed(() => seasonStore.seasonLoaded)

        const modalPlayer = computed(() => {
            return seasonStore.modalPlayerId
                ? seasonStore.players[seasonStore.modalPlayerId]
                : null
        })

        const closeModal = function() {
            seasonStore.modalPlayerId = null
        }

        return {season, canMod, loaded, modalPlayer, closeModal}
    }
}
</script>
