<template>
    <div>
        <button
            v-if="player.status === 'inactive'"
            @click="submitForm('active')"
            class="btn danger"
        >
            INACTIVE - Set Active
        </button>
        <button
            v-if="player.status === 'active'"
            @click="submitForm('inactive')"
            class="btn success"
        >
            ACTIVE - Set Inactive
        </button>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref} from "vue";

export default {
    props: {
        playerId: {
            type: Number,
            required: true
        },
    },
    setup(props) {
        const store = useSeasonStore()

        const player = computed(() => {
            return store.players[props.playerId]
        })

        const submitting = ref(false)

        const submitForm = async (newStatus) => {
            if (submitting.value) {
                return
            }

            submitting.value = true

            try {
                const response = await axios
                    .patch('/api/admin/player-seasons/' + props.playerId, {
                        status: newStatus
                    })

                submitting.value = false

                store.players[props.playerId] = response.data.player_season
            } catch (e) {
                console.log(e)
                submitting.value = false
            }
        }

        return {player, submitForm}
    }
}
</script>
