<template>
    <platform-links-table
        :model-type="modelType"
        :model-id="modelId"
        :season-id="seasonId"
    ></platform-links-table>
</template>

<script>
import {useSeoMeta} from "@unhead/vue";
import PlatformLinksTable from "@/components/PlatformLinksTable.vue";

export default {
    components: {PlatformLinksTable},
    props: {
        seasonId: {
            type: Number,
            default: null
        },
        modelType: {
            type: String,
            default: null
        },
        modelId: {
            type: Number,
            default: null
        }
    },
    setup(props) {
        useSeoMeta({
            title: 'Platform Links',
            description: 'Platform Links'
        })
    }
}
</script>
