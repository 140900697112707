<template>
    <table v-if="rows && rows.total > 0" class="striped">
        <thead>
            <tr>
                <th style="text-align: left">Rank</th>
                <th style="text-align: left">Name</th>
                <th>Total</th>
                <th v-if="withPicks" style="text-align: left">Picks</th>
                <template v-if="rankKey !== 'fixture'">
                    <th>Entries</th>
                    <th>Twenty One's</th>
                    <th>Busts</th>
                </template>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in rows.data">
                <th>
                    #{{ row.rank }}
                </th>
                <td style="text-align: left">
                    <router-link :to="{name: 'twenty-one-user', params: {userId: row.user_id}}">
                        <strong>{{ row.user.name }}</strong>
                    </router-link>
                </td>
                <td>
                    {{ row.points }}
                    <div v-if="row.data.bust">
                        <small class="danger-text">BUST!</small>
                    </div>
                </td>
                <td v-if="withPicks" style="text-align: left">
                    <template v-if="picks.hasOwnProperty(row.user_id)">
                        <div v-for="(teamPicks, teamId) in picks[row.user_id]">
                            <img :src="'/team-logos/' + teamId + '.png'" style="width:16px;display:inline;" />
                            <div v-for="pick in teamPicks" class="inline-block ml-3">
                                <small v-if="pickedPlayers.hasOwnProperty(pick.pick_id)">
                                    <span class="player-score">
                                        {{ playerFixtures.hasOwnProperty(pick.pick_id) ? (playerFixtures[pick.pick_id].points) : 0 }}
                                    </span>
                                    {{ pickedPlayers[pick.pick_id].name }}
                                </small>
                            </div>
                        </div>
                    </template>
                </td>
                <template v-if="rankKey !== 'fixture'">
                    <td>
                        {{ row.data.fixtures || 0 }}
                    </td>
                    <td>
                        {{ row.data.perfects || 0 }}
                    </td>
                    <td>
                        {{ row.data.busts || 0 }}
                    </td>
                </template>
            </tr>
        </tbody>
    </table>
</template>

<script>
import {computed, onMounted, ref} from 'vue';
import miniGameQuery from "../queries/miniGameQuery";
import {useSeasonStore} from "../stores/seasonStore";
export default {
    props: {
        rankKey: {
            type: String,
            required: true
        },
        rankValue: {
            type: [String, Number],
            required: true
        },
        withPicks: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const seasonStore = useSeasonStore()

        const rows = ref(undefined)
        const picks = ref(undefined)
        const pickedPlayers = ref(undefined)
        const playerFixtures = ref(undefined)

        onMounted(() => {
            miniGameQuery.ranks('twenty-one', props.rankKey, props.rankValue, props.withPicks)
                .then(response => {
                    rows.value = response.ranks
                    pickedPlayers.value = _.pick(seasonStore.players, _.map(response.picks, 'pick_id'))
                    picks.value = _.mapValues(_.groupBy(response.picks,'user_id'),userPicks => {
                        return _.groupBy(userPicks, pick => {
                            return pickedPlayers.value[pick.pick_id].team_id
                        })
                    })

                    playerFixtures.value = _.keyBy(response.playerFixtures, 'player_season_id')
                })
        })

        return {rows, picks, playerFixtures, pickedPlayers}
    }
}
</script>
