<template>
    <div>
        <div v-if="activePlayerId">
            <player-history
                :player-id="activePlayerId"
                @close="activePlayerId = null"
            ></player-history>
        </div>
        <div v-else id="admin-page">
            <div class="flex items-center">
                <player-filter
                    v-model="filter"
                ></player-filter>
                <button class="btn" @click.prevent="resetFilter">
                    Clear
                </button>
                <router-link :to="{name: 'admin-create-player'}" class="btn">
                    Add Player
                </router-link>
            </div>
            <div class="stats-table relative overflow-x-auto">
                <table v-if="players" class="w-full">
                    <thead>
                    <tr>
                        <th>
                            Age
                        </th>
                        <th>
                            State
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            Position
                        </th>
                        <th>
                            Team
                        </th>
                        <th colspan="2">
                            Value
                        </th>
                        <th>
                            Points
                        </th>
                        <th>
                            Selected
                        </th>
                        <th>
                            App
                        </th>
                        <th>
                            PPG
                        </th>
                        <th>
                            Min
                        </th>
                        <th>
                            Gls
                        </th>
                        <th>
                            Ass
                        </th>
                        <th>
                            ClS
                        </th>
                        <th>
                            Notes
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        <admin-player-values-table-row
                            v-for="player in players"
                            :key="player.id"
                            :player-id="player.id"
                            @select="activePlayerId = player.player_id"
                            @selectPosition="filter.position = $event"
                            @selectTeam="filter.team_id = $event"
                        ></admin-player-values-table-row>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, computed} from "vue";
import {useSeasonStore} from "../stores/seasonStore.js";
import PlayerFilter from "../components/PlayerFilter.vue";
import PlayerHistory from "../components/PlayerSeasonsList.vue";
import AdminPlayerValuesTableRow from "@/components/AdminPlayerValuesTableRow.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {AdminPlayerValuesTableRow, PlayerHistory, PlayerFilter},
    setup() {
        useSeoMeta({
            title: 'Admin Values',
            description: 'Edit values of players'
        })

        const activePlayerId = ref(null)

        const store = useSeasonStore();

        const players = computed(() => {
            return store.filterPlayers(filter.value)
        })

        const resetFilter = () => {
            filter.value = {
                team_id: 'ALL',
                value: 'ALL',
                position: 'ALL',
            }
        }

        const filter = ref({
            team_id: 'ALL',
            value: 'ALL',
            position: 'ALL',
            sort: 'team',
            sort_dir: 'asc'
        })

        return {players, filter, resetFilter, activePlayerId}
    }
}
</script>
