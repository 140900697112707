<template>
    <div v-if="statusValue">
        <select v-model="statusValue" @change="onChange" :class="bgs[statusValue]">
            <option v-for="(statusOption, value) in statusOptions" :value="value" :disabled="statusOption.disabled">
                {{ statusOption.label }}
            </option>
        </select>
    </div>
</template>
<script>
import {computed, onMounted, ref, watch} from "vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['change', 'success', 'error'],
    props: {
        transfer: {
            type: Object,
            required: true
        },
        autoSave: {
            type: Boolean,
            required: true
        }
    },
    setup(props, {emit}) {
        const saving = ref(false)

        const statusOptions = ref({
            completed: {
                label: 'Completed',
                disabled: false
            },
            pending: {
                label: 'Pending',
                disabled: false
            }
        })

        const statusValue = ref(null)

        const bgs = {
            completed: '',
            pending: 'bg-red-200'
        }

        onMounted(() => {
            statusValue.value = props.transfer.status
        })

        watch(() => props.transfer, (newTransfer, oldTransfer) => {
            statusValue.value = newTransfer.status
        })

        const onChange = () => {
            if (props.autoSave) {
                submitForm()
            } else {
                emit('change', statusValue.value)
            }
        }


        const submitForm = () => {
            saving.value = true

            axios
                .patch('/api/transfers/' + props.transfer.id, {
                    status: statusValue.value
                })
                .then(response => {
                    useFlashStore().setFlash('success', 'Transfer Updated')
                    emit('success', response)
                })
                .catch(error =>  {
                    emit('error', error)
                    useFlashStore().axiosError(error, 'Error Updating Transfer')
                    statusValue.value = props.transfer.status
                })
                .finally(() => {
                    saving.value = false
                })
        }

        return {saving, statusOptions, statusValue, bgs, onChange, submitForm}
    }
}
</script>
