<template>
    <div class="pitch-container">
        <div class="pitch">
            <div v-for="groupId in groups" class="row" :class="groupId">
                <picker-player
                    v-for="player in groupedStarters[groupId]"
                    :key="player.id"
                    :player="player"
                    :is-selected="player.id === selectedPlayerId"
                    :is-captain="player.id === captainId"
                    :is-vice-captain="player.id === viceCaptainId"
                    :is-sub="false"
                    info-type="opposition"
                    @selected="selectPlayer"
                ></picker-player>
            </div>
            <div class="row SUBS">
                <picker-player
                    v-for="player in subs"
                    :key="player.id"
                    :player="player"
                    :is-selected="player.id === selectedPlayerId"
                    :is-captain="player.id === captainId"
                    :is-vice-captain="player.id === viceCaptainId"
                    :is-sub="true"
                    info-type="opposition"
                    @selected="selectPlayer"
                ></picker-player>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref} from "vue";
import PickerPlayer from "./PickerPlayer.vue";
import {useTeamPickerStore} from "../stores/teamPickerStore.js";

export default {
    components: {PickerPlayer},
    setup() {
        const store = useSeasonStore()
        const teamPickerStore = useTeamPickerStore()

        const selectedPlayerId = computed(() => {
            return teamPickerStore.selectedPlayerId
        })

        const captainId = computed(() => {
            return teamPickerStore.captainId
        })

        const viceCaptainId = computed(() => {
            return teamPickerStore.viceCaptainId
        })

        const groups = ['GK', 'DEF', 'MID', 'ATT']

        const starters = computed(() => {
            return _.filter(_.map(teamPickerStore.starters, id => {
                return teamPickerStore.players[id]
            }))
        })

        const groupedStarters = computed(() => {
            return _.groupBy(starters.value, 'position')
        })

        const subs = computed(() => {
            return _.filter(_.map(teamPickerStore.subs, id => {
                return teamPickerStore.players[id]
            }))
        })
        const selectPlayer = (playerId) => {
            teamPickerStore.selectPlayer(playerId)
        }

        return {selectedPlayerId, captainId, viceCaptainId, groups, groupedStarters, selectPlayer, subs}
    }
}
</script>
