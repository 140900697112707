<template>
    <tr v-if="player">
        <template v-for="columnKey in columns">
            <td v-if="columnKey === 'player_id'">
                {{ player.player_id }}
            </td>
            <td v-else-if="columnKey === 'player_season_id'">
                {{ player.id }}
            </td>
            <td v-else-if="columnKey === 'season'">
                {{ season.name }}
            </td>
            <td v-else-if="columnKey === 'name'">
                <button @click.prevent="$emit('select', player)" class="btn mr-2">
                    <i class="fa-solid fa-info"></i>
                </button>
                <router-link :to="{name: routeName, params: {seasonId: player.season_id, playerId: player.id}}">
                    {{ player.name }}
                </router-link>
            </td>
            <td v-else-if="columnKey === 'display_name'">
                {{ player.display_name }}
            </td>
            <td v-else-if="columnKey === 'team'">
                <a v-if="team" href="#" @click.prevent="$emit('selectTeam', team.id)">
                    {{ team.short_name }}
                </a>
            </td>
            <td v-else-if="columnKey === 'status'">
                {{ player.status }}
            </td>
            <td v-else-if="columnKey === 'status_input'">
                <player-status-select
                    @success="$emit('success', $event)"
                    @error="$emit('error', $event)"
                    :auto-save="true"
                    :player="player"
                ></player-status-select>
            </td>
            <td v-else-if="columnKey === 'mod_status'">
                {{ player.mod_status }}
            </td>
            <td v-else-if="columnKey === 'position'">
                <a href="#" @click.prevent="$emit('selectPosition', player.position)">
                    {{ player.position }}
                </a>
            </td>
            <td v-else-if="columnKey === 'position_input'">
                <player-position-input
                    @success="$emit('success', $event)"
                    @error="$emit('error', $event)"
                    :auto-save="true"
                    :player="player"
                ></player-position-input>
            </td>
            <td v-else-if="columnKey === 'selected_by'">
                {{ player.selected_by_percent ? player.selected_by_percent.toFixed(2) : 0 }}%
            </td>
            <td v-else-if="columnKey === 'value'">
                {{ player.pretty_value }}
            </td>
            <td v-else-if="columnKey === 'value_input'">
                <player-value-input
                    @success="$emit('success', $event)"
                    @error="$emit('error', $event)"
                    :auto-save="true"
                    :player="player"
                ></player-value-input>
            </td>
            <td v-else-if="columnKey === 'points'">
                {{ player.points }}
            </td>
            <td v-else-if="columnKey === 'appearances'">
                {{ player.appearances }}
            </td>
            <td v-else-if="columnKey === 'age'">
                {{ player.age }}
            </td>
            <td v-else-if="columnKey === 'date_of_birth'" :class="{warning: player.dob_match}">
                {{ player.date_of_birth }}
            </td>
            <td v-else-if="columnKey === 'urls'">
                <a v-if="player.player.platform_url" :href="player.player.platform_url" target="_blank">
                    {{ player.player.platform_url }}
                </a>
            </td>
            <td v-else-if="columnKey === 'created_at'">
                {{ createdAt }}
            </td>
            <td v-else-if="columnKey === 'points'">
                {{ player.points }}
            </td>
            <td v-else-if="columnKey === 'similarity'">
                {{ player.similarity }}
            </td>
            <td v-else-if="columnKey === 'merge_button'">
                <button
                    class="btn danger"
                    @click.prevent="$emit('merge')"
                    :disabled="player.status === 'duplicate'"
                >
                    Merge
                </button>
            </td>
            <td v-else>
                Unknown Key "{{ columnKey }}"
            </td>
        </template>
    </tr>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import dayjs from "dayjs";
import {useDefaultStore} from "@/stores/defaultStore";
import PlayerStatusSelect from "@/components/PlayerStatusSelect.vue";
import PlayerValueInput from "@/components/PlayerValueInput.vue";
import PlayerPositionInput from "@/components/PlayerPositionInput.vue";

export default {
    components: {PlayerPositionInput, PlayerValueInput, PlayerStatusSelect},
    props: {
        player: {
            type: Object,
            required: true
        },
        columns: {
            type: Array,
            required: true
        },
        routeName: {
            type: String,
            required: true
        }
    },
    emits: ['select', 'selectTeam', 'selectPosition', 'merge', 'success', 'error'],
    setup(props) {
        const defaultStore = useDefaultStore()
        const seasonStore = useSeasonStore()

        const team = computed(() => {
            return props.player.team || seasonStore.teams[props.player.team_id]
        })

        const season = computed(() => {
            return defaultStore.seasons[props.player.season_id]
        })

        const createdAt = computed(() => {
            return props.player.created_at
                ? dayjs(props.player.created_at).format('YYYY-MM-DD HH:mm:ss')
                : null
        })

        const emitError = () => {
            console.log('test error')
        }

        return {team, season, createdAt, emitError}
    }
}
</script>
