<template>
    <div>
        <div class="box blue-gradient">
            <h1>Players Requiring Moderation</h1>
            <admin-player-table :players="modRequiredPlayers"></admin-player-table>
        </div>
        <div class="box green-gradient">
            <h1>Fixtures Requiring Moderation</h1>
            <div v-for="fixture in modRequiredFixtures">
                <div>{{ fixture.description }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, computed} from "vue";
import {useSeasonStore} from "../stores/seasonStore.js";
import AdminPlayerTable from "@/components/AdminPlayerTable.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {AdminPlayerTable},
    setup() {
        const seasonStore = useSeasonStore();

        useSeoMeta({
            title: () => 'Admin ' + seasonStore.season.name,
            description: 'Edit a season'
        })

        const modRequiredPlayers = computed(() => {
            return _.filter(seasonStore.players, player => !!player.mod_status)
        })

        const modRequiredFixtures = computed(() => {
            return _.filter(seasonStore.fixtures, fixture => !!fixture.mod_status)
        })

        return {modRequiredPlayers, modRequiredFixtures}
    }
}
</script>
