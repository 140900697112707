<template>
    <div class="flex flex-col md:flex-row lg:flex-col xl:flex-row">
        <template v-for="field in fields">
            <div v-if="field === 'position'" class="mr-3">
                <select :value="modelValue.position" @input="updatePosition">
                    <option value="ALL">
                        All Positions
                    </option>
                    <option v-for="(optionName, optionValue) in filterOptions.positions" :value="optionValue">
                        {{ optionName }}
                    </option>
                </select>
            </div>
            <div v-if="field === 'team' && (!defaults || !defaults.team_id)" class="mr-3">
                <select :value="modelValue.team_id" @input="updateTeam">
                    <option value="ALL">
                        All Teams
                    </option>
                    <option v-for="(optionTeam) in filterOptions.teams" :value="optionTeam.id">
                        {{ optionTeam.name }}
                    </option>
                </select>
            </div>
            <div v-if="field === 'team' && (!defaults || !defaults.team_id)" class="mr-3">
                <select :value="modelValue.value" @input="updateValue">
                    <option value="ALL">
                        Any Value
                    </option>
                    <option v-if="affordableValue" value="AFFORDABLE">
                        Affordable
                    </option>
                    <option v-for="(valueOption) in filterOptions.values" :value="valueOption.value">
                        {{ valueOption.label }}
                    </option>
                </select>
            </div>
            <div v-if="field === 'sort' && (!defaults || !defaults.sort)" class="mr-3 flex-default">
                <label class="mr-3">Sort: </label>
                <select :value="modelValue.sort" @input="updateSort">
                    <option v-for="(optionName, optionValue) in filterOptions.sort" :value="optionValue">
                        {{ optionName }}
                    </option>
                </select>
                <select :value="modelValue.sort_dir" @input="updateSortDir">
                    <option v-for="(optionName, optionValue) in filterOptions.sort_dir" :value="optionValue">
                        {{ optionName }}
                    </option>
                </select>
            </div>

        </template>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref, watch} from "vue";

export default {
    props: {
        modelValue: {
            type: Object,
            required: true
        },
        defaults: {
            type: Object,
            default: () => {}
        },
        fields: {
            type: Array,
            default: [
                'position',
                'team',
                'sort'
            ]
        },
        affordableValue: {
            type: Number,
            default: undefined
        }
    },
    emits: ['update:modelValue'],
    setup(props, {emit}) {
        const store = useSeasonStore()

        const filterOptions = {
            positions: {
                GK: 'Goalkeepers',
                DEF: 'Defenders',
                MID: 'Midfielders',
                ATT: 'Attackers'
            },
            teams: _.sortBy(_.filter(store.teams, team => {
                    if (!props.defaults || !props.defaults.team_id || props.defaults.team_id === 'ALL') {
                        return true
                    } else if (Array.isArray(props.defaults.team_id)) {
                        return props.defaults.team_id.includes(team.id)
                    } else {
                        return team.id === parseInt(props.defaults.team_id)
                    }
                }), 'name'),
            values: _.map(
                (new Float64Array(_.uniq(_.map(_.filter(store.players, player => player.status === 'active' && (player.value % 10 === 0 || player.value % 10 === 5)), 'value')))).sort(),
                value => {
                    return {
                        value: value,
                        label: (value/10).toFixed(1)
                    }
                }
            ),
            sort: {
                name: 'Name',
                team: 'Team',
                position: 'Position',
                selected_by: 'Selected %',
                points: 'Points',
                points_per_game: 'Points Per Game',
                points_per_appearance: 'Points Per Appearance',
                points_per_ninety: 'Points Per 90',
                points_per_start: 'Points Per Start',
                appearances: 'Appearances',
                starts: 'Starts',
                minutes: 'Minutes',
                created_at_timestamp: 'Date Added',
            },
            sort_dir: {
                asc: 'Asc',
                desc: 'Desc'
            }
        }

        if (store.season.budget !== null) {
            filterOptions.sort = {
                value: 'Value',
                ...filterOptions.sort
            }
        }

        const updatePosition = (event) => {
            updateFilter({position: event.target.value})
        }

        const updateTeam = (event) => {
            updateFilter({team_id: event.target.value})
        }

        const updateValue = (event) => {
            updateFilter({value: event.target.value})
        }

        const updateSort = (event) => {
            updateFilter({sort: event.target.value})
        }

        const updateSortDir = (event) => {
            updateFilter({sort_dir: event.target.value})
        }

        watch(() => props.affordableValue, () => {
            updateFilter({})
        })

        const updateFilter = (changes) => {
            emit('update:modelValue', {
                ...props.modelValue,
                ...changes,
                affordable_value: props.affordableValue,
            })
        }

        return {filterOptions, updatePosition, updateTeam, updateValue, updateSort, updateSortDir}
    }
}
</script>
