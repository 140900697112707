<template>
    <div>
        <h3>Captain</h3>
        <div class="paragraphs">
            <p>Your Captain scores double. If your captain doesn't play any minutes then your Vice Captain will score double instead. If neither your Captain or your Vice Captain play then you will not get any bonus points this week.</p>
        </div>
        <h3>Subs</h3>
        <div class="paragraphs">
            <p>You have 4 subs at the bottom. They only score points if one of your starters doesn't feature in a match.</p>
            <p>Subs are automatic and the left most player in your subs bench is substituted in first, excluding GKs, only a GK will be swapped with a GK.</p>
            <p>A player will be subbed in if they have featured in a match. "Featured" includes playing any minutes or receiving a yellow or red card. Since it is possible for a substitute to receive a card without playing any minutes.</p>
            <p>If more than 3 of your starters don't feature then i'm afraid you're out of luck and score no points for that player.</p>
            <p>Similarly if your subs score points but your starters all play minutes, you're out of luck, you won't get the points from your substitutes.</p>
        </div>
    </div>
</template>
<script setup lang="ts">
</script>
