<template>
    <div v-if="miniGame">
        <div class="p-3">
            <h1>Play Twenty Ones</h1>
            <div class="paragraphs">
                <p>Twenty Ones is alot like the card games Pontoon or Blackjack. All you have to do is pick players from a match and try and get their combined score to be as close to 21 as possible without going over. If you score more than 21 points you bust and get 0 points.</p>
                <p>To get started simply pick a match from the list below. You will the be presented with players from that match. Pick as many as you like, but don't forget, the more players you pick, the greater chance you'll have of scoring more than 21 points and busting.</p>
                <p>Think about whether it's better to pick a few high scoring players or lots of low scoring players. Which tactic will you chose?</p>
            </div>
        </div>
        <div v-if="!authUser" class="p-3">
            <router-link :to="{name:'auth'}" class="btn success">
                {{ authUser ? 'Home' : 'Login / Register' }}
            </router-link>
        </div>
        <router-view v-else></router-view>
    </div>
</template>
<script>

import {computed, onMounted} from "vue";
import {useMiniGameStore} from "../stores/miniGameStore";
import {useDefaultStore} from "../stores/defaultStore";

export default {
    props: {
        seasonId: {
            type: [String,Number],
            required: false
        },
    },
    setup(props) {
        const store = useDefaultStore()
        const miniGameStore = useMiniGameStore()

        onMounted(() => {
            miniGameStore.init('twenty-one', props.seasonId)
        })

        const miniGame = computed(() => miniGameStore.miniGame)

        const authUser = computed(() => {
            return store.authUser;
        })

        return {miniGame, authUser}
    }
}
</script>
