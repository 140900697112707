<template>
    <form v-if="isEditable" @submit.prevent="submitForm">
        <input type="number" v-model="value" style="width:60px" :disabled="saving" :class="{'bg-orange-200': !value}">
    </form>
    <input v-else type="number" v-model="value" style="width:60px" :disabled="true" class="bg-gray-400">
</template>
<script>
import {computed, onMounted, ref} from "vue";
import {useSeasonStore} from "@/stores/seasonStore";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['input', 'success', 'error'],
    props: {
        player: {
            type: Object,
            required: true
        },
        autoSave: {
            type: Boolean,
            required: true
        }
    },
    setup(props, {emit}) {
        const seasonStore = useSeasonStore()

        const isEditable = computed(() => {
            return seasonStore.season.status !== 'active'
                || props.player.selected_by < 1
        })

        const saving = ref(false)
        const value = ref(null)


        onMounted(() => {
            value.value = props.player.value
        })

        const submitForm = () => {
            if (!props.autoSave) {
                emit('input', value.value)
                return;
            }
            saving.value = true

            seasonStore
                .updatePlayer(props.player.id, {value: value.value})
                .then(response => {
                    useFlashStore().setFlash('success', 'Player Value Updated!')
                    emit('success', response)
                })
                .catch(error =>  {
                    emit('error', error)
                    useFlashStore().axiosError(error, 'Error updating player value')
                    value.value = props.player.value
                })
                .finally(() => {
                    saving.value = false
                })
        }

        return {isEditable, saving, value, submitForm}
    }
}
</script>
