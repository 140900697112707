<template>
    <router-view v-if="canAdmin"></router-view>
</template>
<script>
import {computed} from "vue";
import {useDefaultStore} from "@/stores/defaultStore";
import {useSeoMeta} from "@unhead/vue";

export default {
    setup() {
        useSeoMeta({
            title: 'Admin'
        })
        const defaultStore = useDefaultStore()

        const canAdmin = computed(() => {
            return defaultStore.authUser.id === 1
        })

        return {canAdmin}
    }
}
</script>
