<template>
    <tr class="fixture-table-row">
        <td>
            GW {{ gameweek.number }}
        </td>
        <td>
            {{ prettyDate }}
        </td>
        <td>
            <strong>
                {{ opponent.name}} ({{ location }})
            </strong>
        </td>
    </tr>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import dayjs from 'dayjs';

export default {
    props: {
        fixture: {
            type: Object,
            required: true
        },
        participantId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const location = computed(() => {
            return (props.participantId === props.fixture.home_team_id)
                ? 'H'
                : 'A'
        })

        const opponent = computed(() => {
            if (props.participantId === props.fixture.home_team_id) {
                return store.teams[props.fixture.away_team_id]
            } else {
                return store.teams[props.fixture.home_team_id]
            }
        })

        const prettyDate = dayjs(props.fixture.kickoff).format('HH:mm ddd, MMM D');

        const gameweek = computed(() => {
            return store.gameweeks[props.fixture.gameweek_id]
        })

        return {location, opponent, prettyDate, gameweek}
    }
}
</script>
