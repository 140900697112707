<template>
    <div class="pitch-container">
        <div class="pitch">
            <div v-for="groupId in groups" class="row" :class="groupId">
                <picker-player
                    v-for="player in groupedPlayers[groupId]"
                    :key="player.id"
                    :player="player"
                    :is-selected="player.id === selectedPlayerId"
                    :info-type="infoType"
                    :sale-value="saleValues[player.id]"
                    @selected="selectPlayer"
                ></picker-player>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref} from "vue";
import PickerPlayer from "./PickerPlayer.vue";
import {useSquadPickerStore} from "../stores/squadPickerStore.js";

export default {
    components: {PickerPlayer},
    props: {
    },
    setup() {
        const store = useSeasonStore()
        const squadPickerStore = useSquadPickerStore()

        const infoType = ref(store.season.budget === null ? 'team' : 'sale_value')

        const selectedPlayerId = computed(() => {
            return squadPickerStore.selectedPlayerId
        })

        const players = computed(() => {
            return squadPickerStore.players
        })

        const saleValues = computed(() => {
            return squadPickerStore.saleValues
        })

        const groups = ['GK', 'DEF', 'MID', 'ATT']

        const groupedPlayers = computed(() => {
            return _.groupBy(players.value, 'position')
        })

        const selectPlayer = (playerId) => {
            squadPickerStore.selectPlayer(playerId)
        }

        return {infoType, selectedPlayerId, groups, groupedPlayers, saleValues, selectPlayer}
    }
}
</script>
