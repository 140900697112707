<template>
    <fixture-list-row
        :fixture="fixture"
        route-name="twenty-one-fixture"
    >
    </fixture-list-row>
    <div v-if="hasPickedPlayers" style="background:rgba(0,0,0,0.5);padding:.3rem 0">
        <div v-if="rank" class="text-center">Rank #{{ rank.rank }}</div>
        <div class="fixture-row">
            <div class="home-team">
                <template v-if="pickedPlayers.hasOwnProperty(fixture.home_team_id)">
                    <div v-for="pickedPlayer in pickedPlayers[fixture.home_team_id]" class="ml-3">
                        <small>
                            {{ pickedPlayer.name }}
                            <span class="player-score">
                                {{ keyedPlayerFixtures.hasOwnProperty(pickedPlayer.id) ? keyedPlayerFixtures[pickedPlayer.id].points : 0 }}
                            </span>
                        </small>
                    </div>
                </template>
            </div>

            <div>
                <span v-if="!rank" class="overall-score">
                    -
                </span>
                <span v-else-if="rank.data.bust" class="overall-score error">
                    {{ rank.data.score }}
                </span>
                <span v-else class="overall-score success">
                    {{ rank.points }}
                </span>
            </div>

            <div class="away-team">
                <template v-if="pickedPlayers.hasOwnProperty(fixture.away_team_id)">
                    <div v-for="pickedPlayer in pickedPlayers[fixture.away_team_id]" class="ml-3">
                        <small>
                            <span class="player-score">
                                {{ keyedPlayerFixtures.hasOwnProperty(pickedPlayer.id) ? keyedPlayerFixtures[pickedPlayer.id].points : 0 }}
                            </span>
                            {{ pickedPlayer.name }}
                        </small>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref} from "vue";
import FixtureListRow from "./FixtureListRow.vue";
import dayjs from 'dayjs';
import GameweekPagination from "./GameweekPagination.vue";
import {useMiniGameStore} from "../stores/miniGameStore";

export default {
    components: {FixtureListRow},
    props: {
        fixture: {
            type: Object,
            required: true
        },
        picks: {
            type: Array,
            required: false
        },
        playerFixtures: {
            type: Array,
            required: false
        },
        rank: {
            type: Object,
            required: false
        }
    },
    setup(props) {
        const seasonStore = useSeasonStore()
        const miniGameStore = useMiniGameStore()

        const hasPickedPlayers = computed(() => {
            return Object.keys(pickedPlayers.value).length > 0
        })

        const pickedPlayers = computed(() => {
            return _.groupBy(
                _.map(props.picks, pick => {
                    return seasonStore.players[pick.pick_id]
                }), 'team_id'
            )
        })

        const keyedPlayerFixtures = computed(() => {
            return _.keyBy(props.playerFixtures, 'player_season_id')
        })

        return {hasPickedPlayers, pickedPlayers, keyedPlayerFixtures}
    }
}
</script>
