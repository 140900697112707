import {defineStore} from 'pinia'
import {computed, toRaw} from "vue";
import {useSeasonStore} from "./seasonStore";

export const useSquadPickerStore = defineStore('squadPickerStore', {
    state: () => ({
        loaded: false,
        entryId: undefined,
        gameweek: undefined,
        maxPicksPerTeam: 3,
        seasonChipId: null,
        chipOptionId: null,
        originalSeasonChipId: null,
        originalChipOptionId: null,
        picks: [],
        originals: [],
        saleValues: {},
        costs: {},
        selectedPlayerId: null,
        requiredPositions: {
            'GK': 2,
            'DEF': 5,
            'MID': 5,
            'ATT': 3,
        },
        transfers: {},
        existingTransfers: 0,
        allowedFreeTransfers: 0,
        unlimitedFreeTransfers: false,
        canUseChips: false,
    }),
    getters: {
        bank: (state) => {
            return _.get(useSeasonStore().authEntry,'bank')
        },
        hasChanged: (state) => {
            return [...state.picks].sort().toString() !== [...state.originals].sort().toString()
                || state.seasonChipId !== state.originalSeasonChipId
                || state.chipOptionId !== state.originalChipOptionId
        },
        originalPlayers: (state) =>  {
            return _.keyBy(_.map(state.originals, (id) => useSeasonStore().players[id]), 'id')
        },
        players: (state) => {
            return _.keyBy(_.map(state.picks, (id) => useSeasonStore().players[id]), 'id')
        },
        selectedPlayer: (state) => {
            return state.selectedPlayerId ? useSeasonStore().players[state.selectedPlayerId] : null
        },
        positionCounts: (state) => {
            return _.mapValues(state.requiredPositions, (min, position) => {
                return _.countBy(state.players, 'position')[position] || 0
            })
        },
        requiredPositionCounts: (state) => {
            return _.mapValues(state.requiredPositions, (min, position) => {
                return min - (state.positionCounts[position] || 0)
            })
        },
        teamCounts: (state) => {
            return _.mapValues(_.groupBy(state.players, 'team_id'), 'length')
        },
        oversizedTeamIds: (state) => {
            return state.maxPicksPerTeam
                ? _.keys(_.pickBy(state.teamCounts, count => count > state.maxPicksPerTeam))
                : []
        },
        inIds: (state) => {
            return state.picks.filter(x => !state.originals.includes(x))
        },
        outIds: (state) => {
            return state.originals.filter(x => !state.picks.includes(x))
        },
        outValues: (state) => {
            return _.pick(state.saleValues, state.outIds)
        },
        inValues: (state) => {
            return _.mapValues(_.pick(state.players, state.inIds), 'value')
        },
        moneySpent: (state) => {
            return _.sum(Object.values(state.inValues))
        },
        moneyReceived: (state) => {
            return _.sum(Object.values(state.outValues))
        },
        moneyDiff: (state) => {
            return state.moneyReceived - state.moneySpent
        },
        moneyRemaining: (state) => {
            return (state.bank + state.moneyDiff)/10
        },
        affordableValue: (state) => {
            return (state.bank + state.moneyDiff) + (state.selectedPlayer ? state.selectedPlayer.value : 0)
        },
        hasValidationErrors: (state) => {
            return state.validationErrors.length > 0
        },
        totalTransfers: (state) => {
            return Object.values(state.transfers).length + (state.existingTransfers.total || 0)
        },
        newActiveTransfersCount: (state) => {
            return _.size(state.transfers)
        },
        existingActiveTransfersCount: (state) => {
            return (state.existingTransfers.total || 0)
        },
        totalActiveTransfers: (state) => {
            return state.newActiveTransfersCount + (state.existingTransfers.total || 0)
        },
        freeTransfersRemaining: (state) => {
            return state.existingActiveTransfersCount > state.allowedFreeTransfers ? 0 : state.allowedFreeTransfers - state.existingActiveTransfersCount
        },
        pointsCost: (state) => {
            return state.totalActiveTransfers > state.freeTransfersRemaining && state.gameweek.cost_per_transfer
                ? (state.newActiveTransfersCount - state.freeTransfersRemaining) * state.gameweek.cost_per_transfer
                : 0
        },
        validationErrors: (state) => {
            return _.filter([
                ..._.map(state.oversizedTeamIds, teamId => 'You have ' + state.teamCounts[teamId] + ' players from ' + useSeasonStore().teams[teamId].name + ' you must not have more than ' + state.maxPicksPerTeam),
                state.moneyRemaining < 0 ? 'Insufficient funds to complete transfers. This would leave you with ' + state.moneyRemaining + ' in the bank' : null,
                state.requiredPositions['GK'] > state.positionCounts['GK'] ? 'You must have exactly ' + state.requiredPositions['GK'] + ' GK' : null,
                state.requiredPositions['DEF'] > state.positionCounts['DEF'] ? 'You must have exactly ' + state.requiredPositions['DEF'] + ' DEF' : null,
                state.requiredPositions['MID'] > state.positionCounts['MID'] ? 'You must have exactly ' + state.requiredPositions['MID'] + ' MID' : null,
                state.requiredPositions['ATT'] > state.positionCounts['ATT'] ? 'You must have exactly ' + state.requiredPositions['ATT'] + ' ATT' : null,
            ])
        }
    },
    actions: {
        async init(entryId, gameweek) {
            this.entryId = entryId
            this.gameweek = gameweek
            this.maxPicksPerTeam = gameweek.picks_per_team
            this.transfers = {}
            try {
                const response = await axios.get('/api/entry/' + entryId + '/gameweek/' + gameweek.id)
                useSeasonStore().setAuthEntry(response.data.entry)

                this.existingTransfers = response.data.transfers
                this.allowedFreeTransfers = response.data.allowed_free_transfers
                this.unlimitedFreeTransfers = response.data.unlimited_free_transfers
                this.canUseChips = response.data.can_use_chips
                this.picks = _.map(response.data.picks, 'player_season_id') || []
                this.originals = _.map(response.data.picks, 'player_season_id') || []
                this.saleValues = response.data.sale_values || {}
                this.costs = _.pluck(response.data.picks, 'cost', 'player_season_id') || {}
                this.seasonChipId = response.data.season_chip_id
                this.chipOptionId = response.data.chip_option_id
                this.originalSeasonChipId = response.data.season_chip_id
                this.originalChipOptionId = response.data.chip_option_id
                this.loaded = true
            } catch (error) {
                console.log(error)
            }
        },
        canAddPosition(position) {
            return !this.positionCounts.hasOwnProperty(position) || this.positionCounts[position] < this.requiredPositions[position]
        },
        swapPlayers(inId, outId) {
            console.log('Swapping ' + inId + ' with ' + outId)

            this.picks = _.map(this.picks, (id) => id === outId ? inId : id)

            this.addTransfer(inId, outId)
        },
        addTransfer(inId, outId) {
            if (this.originals.includes(inId) && this.originals.includes(outId)) {
                console.log('Both players were originally in')
                // At some point the inId had been transferred out so we swap for who they'd been transferred to
                this.transfers[outId] = this.transfers[inId]
                delete this.transfers[inId]
            } else if(this.originals.includes(outId) && !this.originals.includes(inId)) {
                console.log('Out is original but in is not')
                this.transfers[outId] = inId
            } else if(this.transfers[inId] === outId) {
                // Just undoing a past transfer
                console.log('Just undoing a past transfer')
                delete this.transfers[inId]
            } else if(!this.originals.includes(outId) && this.originals.includes(inId)) {
                console.log('In is original but out is not')
                // inId was originally in and we're putting it back
                // but outId was transferred with someone else so we need to swap that around
                let originalTransferId = parseInt(_.findKey(this.transfers, (id) => id === outId));

                this.transfers[originalTransferId] = this.transfers[inId]
                delete this.transfers[inId]
            } else {
                console.log('Both players were originally out!')
                let originalTransferId = parseInt(_.findKey(this.transfers, (id) => id === outId));
                if (originalTransferId) {
                    this.transfers[originalTransferId] = inId
                }
            }
        },
        addPlayer(playerId) {
            this.picks.push(playerId)
        },
        removePlayer(playerId) {
            this.picks = _.without(this.picks, playerId)
        },
        selectPlayer(newPlayerId) {
            const newPlayer = useSeasonStore().players[newPlayerId]
            console.log(newPlayer.name + ' SELECTED')
            if (this.selectedPlayerId === newPlayerId) {
                // Selecting already selected player, so just deselect
                this.selectedPlayerId = null
            } else if (!this.selectedPlayerId) {
                console.log('No other player selected')
                if (!this.picks.includes(newPlayerId) && this.canAddPosition(newPlayer.position)) {
                    console.log('Player can be added straight to line up')
                    this.addPlayer(newPlayerId)
                } else {
                    console.log("Requires a transfer so select them")
                    this.selectedPlayerId = newPlayerId
                }
            } else if (this.selectedPlayer.position !== newPlayer.position) {
                // Selecting different positions so switch selectedness
                // Use selectPlayer() again incase they can go straight into the line up
                this.selectedPlayerId = null
                this.selectPlayer(newPlayerId)
            } else if (!this.picks.includes(newPlayerId) && !this.picks.includes(this.selectedPlayerId)) {
                // Selected two players not in line up, so switch selectedness
                // Use selectPlayer() again incase they can go straight into the line up
                this.selectedPlayerId = null
                this.selectPlayer(newPlayerId)
            } else if (this.picks.includes(newPlayerId) && this.picks.includes(this.selectedPlayerId)) {
                // Selected two players already in line up, so switch selectedness
                this.selectedPlayerId = newPlayerId
            } else if (this.picks.includes(newPlayerId) && !this.picks.includes(this.selectedPlayerId)) {
                // Swapping line up player with someone not in line up
                this.swapPlayers(this.selectedPlayerId, newPlayerId)
                this.selectedPlayerId = null
            } else if (this.picks.includes(this.selectedPlayerId) && !this.picks.includes(newPlayerId)) {
                // Swapping list player with line up player
                this.swapPlayers(newPlayerId, this.selectedPlayerId)
                this.selectedPlayerId = null
            } else {
                this.selectedPlayerId = newPlayerId
                console.error('Unable to handle selected player.')
            }
        },
        async savePicks() {
            const response = await axios
                .post('/api/pick-squad', {
                    entry_id: this.entryId,
                    gameweek_id: this.gameweek.id,
                    ids: this.picks,
                    transfers: this.transfers,
                    season_chip_id: this.seasonChipId,
                    chip_option_id: this.chipOptionId,
                })

            useSeasonStore().authEntry = response.data.entry

            await this.init(
                response.data.entry.id,
                response.data.gameweek,
            )
        },
    },
})
