<template>
    <div>
        <nav-bar></nav-bar>
        <div v-if="loaded">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import {onMounted, computed} from 'vue'
import NavBar from "../components/NavBar.vue";
import {useGodStore} from "@/stores/godStore";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {NavBar},
    setup(props) {
        const godStore = useGodStore()

        useSeoMeta({
            title: () => 'God',
            description: () => 'God controls'
        })

        onMounted(() => {
            godStore.init(props.seasonId)
        })

        const loaded = computed(() => godStore.loaded)

        return {loaded}
    }
}
</script>
