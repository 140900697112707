<template>
    <div>
        <div v-if="showControls" class="flex justify-center items-center m-3">
            <!--
            <select v-model="month" class="w-auto">
                <option value="overall">Overall</option>
                <option v-for="(monthLabel, monthKey) in months" :value="monthKey">
                    {{ monthLabel }}
                </option>
            </select>
            -->
            <label>Sort By</label>
            <leaderboard-gameweek-select v-model="sortOrder"></leaderboard-gameweek-select>
            <label class="ml-3">Starting GW</label>
            <leaderboard-gameweek-select
                v-model="startingGw"
                null-text="Any"
            ></leaderboard-gameweek-select>
        </div>
        <div v-if="showControls && entries" class="flex justify-center m-3">
            <numerical-pagination :current-page="entries.meta.current_page" :last-page="entries.meta.last_page" @goTo="getPage($event)"></numerical-pagination>
        </div>
        <div v-if="entries && entries.data.length > 0" class="leaderboard">
            <table>
                <thead>
                <tr>
                    <th v-if="showAdmin">Admin</th>
                    <th @click="sortOrder = 'overall'" class="clickable">Rank</th>
                    <th>Name</th>
                    <th>Start</th>
                    <th @click="sortOrder = 'overall'" class="clickable">Total</th>
                    <template v-if="showGameweeks">
                        <template v-for="gameweek in gameweeks">
                            <th :colspan="gameweek.id === activeGw ? 2 : 1" class="clickable" @click="sortOrder = gameweek.id">
                                GW {{ gameweek.number }}
                            </th>
                        </template>
                    </template>
                </tr>
                </thead>
                <tbody>
                <template v-if="prefixEntries && prefixEntries.length > 0">
                    <leaderboard-row
                        v-for="entry in prefixEntries" :key="entry.id"
                        :entry="entry"
                        :show-gameweeks="showGameweeks"
                        :show-admin="showAdmin"
                        :active-gw="activeGw"
                        :gameweeks="gameweeks"
                        @removeEntry="$emit('removeEntry', $event)"
                    ></leaderboard-row>
                    <tr>
                        <td colspan="999">
                            ...
                        </td>
                    </tr>
                </template>
                <leaderboard-row
                    v-for="entry in entries.data" :key="entry.id"
                    :entry="entry"
                    :show-gameweeks="showGameweeks"
                    :show-admin="showAdmin"
                    :active-gw="activeGw"
                    :gameweeks="gameweeks"
                    @removeEntry="$emit('removeEntry', $event)"
                ></leaderboard-row>
                <template v-if="suffixEntries && suffixEntries.length > 0">
                    <leaderboard-row
                        v-for="entry in suffixEntries" :key="entry.id"
                        :entry="entry"
                        :show-gameweeks="showGameweeks"
                        :show-admin="showAdmin"
                        :active-gw="activeGw"
                        :gameweeks="gameweeks"
                        @removeEntry="$emit('removeEntry', $event)"
                    ></leaderboard-row>
                    <tr>
                        <td colspan="999">
                            ...
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <div v-else-if="entries">
            No entries match filter.
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore.js";
import LeaderboardRow from "../components/LeaderboardRow.vue";
import {computed, ref, watch} from 'vue';
import NumericalPagination from "./NumericalPagination.vue";
import LeaderboardGameweekSelect from "./LeaderboardGameweekSelect.vue";
export default {
    emits: ['getPage', 'removeEntry'],
    components: {LeaderboardGameweekSelect, NumericalPagination, LeaderboardRow},
    props: {
        entries: {
            type: Object,
            required: false
        },
        prefixEntries: {
            type: Array,
            required: false
        },
        suffixEntries: {
            type: Array,
            required: false
        },
        showAdmin: {
            type: Boolean,
            require: true
        },
        showControls: {
            type: Boolean,
            require: true
        }
    },
    setup(props, {emit}) {
        const store = useSeasonStore()

        const gameweeks = computed(() => {
            return _.orderBy(_.filter(store.gameweeks, gameweek => {
                return gameweek.number <= (store.pointsGameweek ? store.pointsGameweek.number : 0)
            }), 'number', 'desc')
        })

        const month = ref('overall')

        const months = computed(() => {
            return store.season.months;
        })

        watch(month, (newVal, oldVal) => {
            getPage(1)
        })

        const sortOrder = ref('overall')

        watch(sortOrder, (newVal, oldVal) => {
            getPage(1)
        })

        const startingGw = ref('overall')

        watch(startingGw, (newVal, oldVal) => {
            getPage(1)
        })

        const activeGw = computed(() => {
            return sortOrder.value === 'overall' ? null : sortOrder.value
        })

        const showGameweeks = computed(() => {
            return month.value === 'overall'
        })

        const getPage = (pageNum) => {
            console.log(pageNum, month.value, activeGw.value)
            emit('getPage', {
                page:pageNum,
                month:month.value,
                activeGw:activeGw.value,
                starting_gameweek_id: startingGw.value
            })
        }

        return {gameweeks, showGameweeks, months, sortOrder, startingGw, activeGw, getPage}
    }
}
</script>
