<template>
    <div>
        <twenty-one-gameweek></twenty-one-gameweek>
    </div>
</template>

<script>
import TwentyOneGameweek from "../components/TwentyOneGameweek.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {TwentyOneGameweek},
    setup() {
        useSeoMeta({
            title: () => '21 | Fantasy Twenty One',
            description: () => "Twenty One is a unique blackjack style Fantasy Football Game. Pick players for each fixture and score their combined Fantasy Points. Try and get as close as 21 as possible to maximise your score but don't score more than 21 otherwise you'll go bust and score 0 points."
        })
    }
}
</script>
