<template>
    <tr>
        <td>
            {{ percent }} %
        </td>
        <th v-if="player">
            <div class="flex items-center">
                <div class="player-team-logo">
                    <img v-if="team.image_path" :src="team.image_path"/>
                </div>
                <div class="grow">
                    <router-link :to="{name:'player', params: {playerId: player.id}}">
                        {{ player.name }}
                    </router-link>
                    <small class="player-details block">
                        <span>{{ player.position }}</span>
                        <span>{{ team.short_name }}</span>
                    </small>
                </div>
            </div>
        </th>
    </tr>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import PickerPlayer from "./PickerPlayer.vue";
import GameweekPagination from "./GameweekPagination.vue";

export default {
    components: {GameweekPagination, PickerPlayer},
    props: {
        playerGameweek: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const player = computed(() => store.players[props.playerGameweek.player_season_id])

        const team = computed(() => store.teams[player.value.team_id])

        const percent = computed(() => {
            return Number(props.playerGameweek.selected_by_percent).toFixed(2)
        })

        return {player, team, percent}
    }
}
</script>
