import _ from 'lodash';
window._ = _;

_.mixin({
    'groupByKeyBy': (value, groupBy, keyBy) => {
        return _.mapValues(_.groupBy(value, groupBy), group => {
            return _.keyBy(group, keyBy)
        })
    },
    'groupByMap': (value, groupBy, predicate) => {
        return _.mapValues(_.groupBy(value, groupBy), group => {
            return _.map(group, predicate)
        })
    },
    'replaceInArray': (arr, predicate, newValue) => {
        const index = _.findIndex(arr, predicate);

        if (index > -1) {
            arr.splice(index, 1, newValue);
        }
        return arr
    },
    'mergeInArray': (arr, predicate, newValue, ifNew = false) => {
        const index = _.findIndex(arr, predicate);

        if (index > -1) {
            arr.splice(index, 1, _.merge(arr[index], newValue));
        } else if (ifNew === 'prepend') {
            arr.unshift(newValue)
        } else if (ifNew === 'append') {
            arr.push(newValue)
        }
        return arr
    },
    'pluck': (value, predicate, keyBy) => {
        return _.mapValues(_.keyBy(value, keyBy), predicate)
    },
    'similarity': (str1, str2, substringLength = 2, caseSensitive = false) => {
        if (!caseSensitive) {
            str1 = str1.toLowerCase();
            str2 = str2.toLowerCase();
        }

        if (str1.length < substringLength || str2.length < substringLength)
            return 0;

        const map = new Map();
        for (let i = 0; i < str1.length - (substringLength - 1); i++) {
            const substr1 = str1.substring(i, i + substringLength);
            map.set(substr1, map.has(substr1) ? map.get(substr1) + 1 : 1);
        }

        let match = 0;
        for (let j = 0; j < str2.length - (substringLength - 1); j++) {
            const substr2 = str2.substring(j, j + substringLength);
            const count = map.has(substr2) ? map.get(substr2) : 0;
            if (count > 0) {
                map.set(substr2, count - 1);
                match++;
            }
        }

        return (match * 2) / (str1.length + str2.length - ((substringLength - 1) * 2));
    }
});


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
window.axios.defaults.withCredentials = true;


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
