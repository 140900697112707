<template>
    <player-list
        class="box"
        :defaults="{team_id: [fixture.home_team_id, fixture.away_team_id]}"
        @selectPlayer="selectPlayer"
        :picks="picks || []"
    ></player-list>
</template>
<script>
import PlayerList from "./PlayerList.vue";
export default {
    components: {PlayerList},
    props: {
        fixture: {
            type: Object,
            required: true
        },
        picks: {
            type: Array,
            required: true
        }
    },
    emits: ['update:picks'],
    setup(props, {emit}) {
        const selectPlayer = (playerId) => {
            if (props.picks.includes(playerId)) {
                emit('update:picks', _.without(props.picks, playerId))
            } else {
                emit('update:picks', [...props.picks, playerId])
            }
        }

        return {selectPlayer}
    }
}
</script>
