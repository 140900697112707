<template>
    <div v-if="authEntry" id="entry-form" class="form box">
        <h1>Update Your Team</h1>
        <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="register_team_name">Team Name</label>
                <input type="text" id="register_team_name"
                       placeholder="" required
                       v-model="teamName">
            </div>
            <div class="mb-3">
                <button>
                    Update Team
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import {computed, ref} from "vue";
import {useSeasonStore} from "../stores/seasonStore";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['success', 'error'],
    setup(props, {emit}) {
        const store = useSeasonStore()

        const authEntry = computed(() => {
            return store.authEntry
        })

        const teamName = ref(authEntry.value ? authEntry.value.name : null)

        const submitForm = () => {
            store
                .updateEntry({name: teamName.value})
                .then(response => {
                    useFlashStore().setFlash('success', 'Entry Updated')
                    emit('success', response)
                })
                .catch(error =>  {
                    useFlashStore().axiosError(error, 'Failed to update team')
                    emit('error', error)
                })
        }

        return {authEntry, teamName, submitForm}
    }
}
</script>
