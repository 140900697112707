<template>
    <div v-if="playerGameweeks && playerGameweeks.length > 0"  id="selection-stats" class="box">
        <h2>Selection Stats</h2>
        <div class="flex justify-center w-full">
            <table  class="w-full">
                <tbody>
                    <selection-stats-row
                        v-for="playerGameweek in paginated"
                        :player-gameweek="playerGameweek"
                    ></selection-stats-row>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import SelectionStatsRow from "./SelectionStatsRow.vue";

export default {
    components: {SelectionStatsRow},
    props: {
        perPage: {
            type: Number,
            default: 25
        }
    },
    setup(props) {
        const store = useSeasonStore()

        onMounted(() => {
            axios
                .get('/api/gameweek/' + store.pointsGwId + '/stats')
                .then(response => {
                    playerGameweeks.value = response.data.playerGameweeks
                })
        })

        const playerGameweeks = ref([])

        const pageNum = ref(1)

        const paginated = computed(() => {
            return _.slice(
                _.orderBy(playerGameweeks.value, 'selected_by', 'desc'),
                props.perPage * (pageNum.value - 1),
                props.perPage
            )
        })

        return {pageNum, paginated, playerGameweeks}
    }
}
</script>
