<template>
    <season-form @success="onSuccess"></season-form>
</template>

<script>
import SeasonForm from "@/components/SeasonForm.vue";
import {useRouter} from "vue-router";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {SeasonForm},
    setup() {
        useSeoMeta({
            title: 'God Create Season',
            description: 'Create a new season'
        })

        const router = useRouter();

        const onSuccess = () => {
            router.push({name: 'god'})
        }

        return {onSuccess}
    }
}
</script>
