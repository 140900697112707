<template>
    <div>
        <div class="px-3 paragraphs">
            <p>Welcome to the Sweep Stake Generator. A sweep stake is a really easy way for a group of mixed interest people to follow a tournament. Each person is assigned a team and if that team wins the tournament that person wins the sweep steak.</p>
            <p>You can set this up with colleagues, family or friends. You can make up your own prizes, possibly even have prizes for second and third place.</p>
            <p>All this tool does is help you quickly assign different teams to different people.</p>
        </div>
        <div class="p-3">
            <label>
                How many people are taking part?
            </label>
            <form @submit.prevent="addPerson">
                <input type="text" v-model="participantName" class="inline w-auto" placeholder="Person Name" />
                <button class="btn success inline">Add Person</button>
                <button class="btn success inline" @click.prevent="generate">Assign Teams</button>
                <span class="ml-3">
                    <input type="checkbox" id="multi-team" v-model="multiTeam" class="inline w-auto mr-2" />
                    <label for="multi-team" class="cursor-pointer">Assign each person {{ result.length > 2 ? teamsPerUser : 'multiple' }} teams to cover every team</label>
                </span>
            </form>
            <ul>
                <li v-for="{person, teams} in result" class="my-1 mx-3">
                    {{ person }}
                    <span v-if="teams.length > 0">has
                        <span v-for="team in teams" class="mr-2">
                            <img v-if="team.image_path" :src="team.image_path" style="width:15px" class="inline" />
                            {{ team.name }}
                        </span>
                    </span>
                    <button @click="removePerson(person)" class="btn danger">X</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {onMounted, ref, computed} from "vue";
import {useSeasonStore} from "../stores/seasonStore.js";
import predictionsQuery from "../queries/predictionsQuery.js";
import PredictionFixtures from "../components/PredictionFixtures.vue";
import {useSeoMeta} from "@unhead/vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    components: {PredictionFixtures},
    setup() {
        const store = useSeasonStore()

        useSeoMeta({
            title: () => 'Sweep Stake ' + store.season.name,
            description: () => 'Sweep Stake for ' + store.season.name
        })

        const participantName = ref('')
        const result = ref([])
        const multiTeam = ref(false)

        const teamsPerUser = computed(() => {
            return result.value.length
                ? Math.ceil(_.size(store.teams) / result.value.length)
                : undefined
        })

        const addPerson = () => {
            if (_.filter(result.value, {person:participantName.value}).length > 0) {
                useFlashStore().setFlash('error', participantName.value + ' is already in the list')

                return;
            }

            result.value.push({
                person: participantName.value,
                teams: []
            })

            participantName.value = ''

        }

        const removePerson = (name) => {
            result.value = _.reject(result.value, {person:name})
        }

        const generate = (name) => {
            result.value = _.map(result.value, (row) => {
                return {
                    person: row.person,
                    teams: []
                }
            })

            const teamsRequired = (multiTeam.value)
                ? result.value.length * teamsPerUser.value
                : result.value.length

            let teams = _.shuffle(Object.values(store.teams))

            let index = 0
            for(let i = 0; i < teamsRequired; i++) {
                if (!result.value[index]) {
                    index = 0
                }

                let team = teams.shift();
                teams.push(team)
                result.value[index].teams.push(team)
                index++
            }
        }

        return {multiTeam, participantName, result, teamsPerUser, generate, addPerson, removePerson}
    }
}
</script>
