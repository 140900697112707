<template>
    <div>

    </div>
</template>
<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import {useDefaultStore} from "../stores/defaultStore";

export default {

    setup(props) {
        const seasonStore = useSeasonStore()
        const defaultStore = useDefaultStore()

        const season = computed(() => seasonStore.season)
        const title = computed(() => defaultStore.title)

        return {season, title}
    }
}
/*
<div class="paragraphs pb-1 px-3">
        <p>Welcome to {{ title }}. If you haven't played before it's really simple. You pick 15 real life soccer players and then you score points if they play well.</p>
        <p v-if="season && !season.budget">Our Fantasy Soccer Game is more accessible than most because there's no budget, pick whoever you want. But if you think that makes it easier you'd be wrong, the best players rarely score the most points every week.</p>
    </div>
 */
</script>
