<template>
    <div class="stats-table relative overflow-x-auto">
        <table v-if="players" class="w-full">
            <thead>
            <tr>
                <template v-for="columnKey in columns">
                    <th v-if="columnKey === 'player_id'">
                        ID
                    </th>
                    <th v-else-if="columnKey === 'player_season_id'">
                        Season ID
                    </th>
                    <th v-else-if="columnKey === 'season'">
                        Season
                    </th>
                    <th v-else-if="columnKey === 'name'">
                        Name
                    </th>
                    <th v-else-if="columnKey === 'display_name'">
                        Display Name
                    </th>
                    <th v-else-if="columnKey === 'team'">
                        Team
                    </th>
                    <th v-else-if="columnKey === 'status' || columnKey === 'status_input'">
                        Status
                    </th>
                    <th v-else-if="columnKey === 'mod_status'">
                        Mod Status
                    </th>
                    <th v-else-if="columnKey === 'position' || columnKey === 'position_input'">
                        Position
                    </th>
                    <th v-else-if="columnKey === 'selected_by'">
                        Selected %
                    </th>
                    <th v-else-if="columnKey === 'value' || columnKey === 'value_input'">
                        Value
                    </th>
                    <th v-else-if="columnKey === 'appearances'">
                        Appearances
                    </th>
                    <th v-else-if="columnKey === 'points'">
                        Points
                    </th>
                    <th v-else-if="columnKey === 'age'">
                        Age
                    </th>
                    <th v-else-if="columnKey === 'date_of_birth'">
                        DOB
                    </th>
                    <th v-else-if="columnKey === 'urls'">
                        URLs
                    </th>
                    <th v-else-if="columnKey === 'created_at'">
                        Created At
                    </th>
                    <th v-else-if="columnKey === 'similarity'">
                        Similarity
                    </th>
                    <th v-else-if="columnKey === 'merge_button'">
                        Merge
                    </th>
                    <th v-else>
                        Unknown Key "{{ columnKey }}"
                    </th>
                </template>
            </tr>
            </thead>
            <tbody>
            <admin-player-table-row
                v-for="player in players"
                :key="player.id"
                :route-name="routeName"
                :player="player"
                :columns="columns"
                @success="success($event)"
                @error="error($event)"
                @select="$emit('select', $event)"
                @selectPosition="$emit('selectPosition', $event)"
                @selectTeam="$emit('selectTeam', $event)"
                @merge="$emit('merge', player)"
            ></admin-player-table-row>
            </tbody>
        </table>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import AdminPlayerTableRow from "@/components/AdminPlayerTableRow.vue";
import {ref} from "vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    components: {AdminPlayerTableRow},
    props: {
        routeName: {
            type: String,
            default: 'admin-player'
        },
        players: {
            type: [Object, Array],
            required: true
        },
        columns: {
            type: Array,
            default: [
                'player_id',
                'player_season_id',
                'name',
                'team',
                'status',
                'mod_status',
                'selected_by',
                'position',
                'value',
                'points',
                'urls',
                'created_at',
            ]
        }
    },
    emits: ['select', 'selectTeam', 'selectPosition', 'merge'],
    setup(props) {
        const store = useSeasonStore()
        const flashStore = useFlashStore()

        const error = (errorMessage) => {
            flashStore.setFlash('error', errorMessage)
        }

        const success = (playerData) => {

        }

        return {error, success}
    }
}
</script>
