<template>
    <div id="admin-inactive-players-page">
        <div v-if="notSeen" class="stats-table relative overflow-x-auto">
            <table class="w-full">
                <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Diff
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="row in notSeen">
                    <td>
                        {{ players[row.player_id].name }}
                    </td>
                    <td>
                        <player-status-button
                            :player-id="players[row.player_id].id"
                        ></player-status-button>
                    </td>
                    <td>
                        {{ row.diff }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-if="otherSeasons" class="stats-table relative overflow-x-auto">
            <table class="w-full">
                <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Diff
                    </th>
                    <th>
                        Description
                    </th>
                </tr>
                </thead>
                <tbody>
                    <template v-for="(playerSeasons,playerId) in otherSeasons">
                        <tr v-for="otherSeason in playerSeasons">
                            <td>
                                {{ players[otherSeason.player_season.player_id].name }}
                            </td>
                            <td>
                                <player-status-button
                                    :player-id="players[otherSeason.player_season.player_id].id"
                                ></player-status-button>
                            </td>
                            <td>
                                {{ otherSeason.diff }}
                            </td>
                            <td>
                                {{ otherSeason.description }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {ref, computed, onMounted} from "vue";
import {useSeasonStore} from "../stores/seasonStore.js";
import AdminPlayerTableRow from "../components/AdminPlayerTableRow.vue";
import PlayerFilter from "../components/PlayerFilter.vue";
import PlayerHistory from "../components/PlayerSeasonsList.vue";
import dayjs from "dayjs";
import PlayerStatusButton from "../components/PlayerStatusButton.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {PlayerStatusButton, PlayerHistory, PlayerFilter, AdminPlayerTableRow},
    setup() {
        useSeoMeta({
            title: 'Inactive Players',
            description: 'List of inactive players'
        })

        const store = useSeasonStore();

        const notSeen = ref(null)
        const otherSeasons = ref(null)

        onMounted(() => {
            axios
                .get('/api/admin/player-seasons/inactive/' + store.seasonId)
                .then(response => {
                    notSeen.value = response.data.not_seen
                    otherSeasons.value = response.data.with_other_seasons
                })
        })

        const players = computed(() => {
            return _.keyBy(store.players, 'player_id')
        })

        const setStatus = async (playerId, status) => {
            await store.updatePlayer(playerId, {status:status})
        }


        return {players, notSeen, otherSeasons, setStatus}
    }
}
</script>
