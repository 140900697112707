<template>
    <div class="pagination-controls">
        <button @click="$emit('goTo', previousDate)" :disabled="previousDisabled">Previous</button>
        <button @click="$emit('goTo', nextDate)" :disabled="nextDisabled">Next</button>
    </div>
</template>
<script>
import {computed} from "vue";

export default {
    props: {
        active: {
            type: Object,
            required: true
        },
        max: {
            type: Object,
            required: false
        },
        min: {
            type: Object,
            required: false
        }
    },
    setup(props) {
        const previousDate = computed(() => {
            return props.active.subtract(1, 'day')
        })

        const nextDate = computed(() => {
            return props.active.add(1, 'day')
        })

        const previousDisabled = computed(() => {
            return props.min && props.min.isAfter(previousDate.value)
        })

        const nextDisabled = computed(() => {
            return props.max && props.max.isBefore(nextDate.value)
        })

        return {previousDate, nextDate, previousDisabled, nextDisabled}
    }
}
</script>

