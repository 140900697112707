<template>
    <player-historical-stats-table
        v-if="rows"
        :rows="rows"
        :player="player"
    ></player-historical-stats-table>
</template>

<script>
import {ref, onMounted} from "vue";
import PlayerHistoricalStatsTable from "@/components/PlayerHistoricalStatsTable.vue";

export default {
    components: {PlayerHistoricalStatsTable},
    props: {
        player: {
            type: Object,
            required: true
        }
    },
    emits: ['close'],
    setup(props) {
        onMounted(() => {
            axios
                .get('/api/players/historical-stats/player/' + props.player.player_id)
                .then(response => {
                    rows.value = response.data.stats
                })
        })

        const rows = ref(undefined)
        return {rows}
    }
}
</script>
