<template>
    <div v-if="loaded" id="squad-values" class="box purple-gradient">
        <div class="flex-default">
            <h2>Player Values</h2>
        </div>
        <div class="paragraphs">
            <p>You receive half the profit you make back when selling. E.g. if you buy a player for 6.2 and they rise to 6.4 or 6.5 you will only receive 6.3 when selling.</p>
            <p>More information is available on the <router-link :to="{name:'season'}" class="font-bold underline">Dashboard</router-link>.</p>
        </div>
        <br />
        <div class="flex justify-center w-full">
            <table class="w-full">
                <thead>
                    <tr>
                        <th>
                            +/-
                        </th>
                        <th>
                            Cost
                        </th>
                        <th>
                            Current
                        </th>
                        <th>
                            Sale Value
                        </th>
                        <th>
                            Player
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="player in players">
                        <squad-values-table-row
                            v-if="player"
                            :key="player.id"
                            :cost="costs[player.id]"
                            :saleValue="saleValues[player.id]"
                            :player="player">
                        </squad-values-table-row>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {useSquadPickerStore} from "../stores/squadPickerStore";
import {computed, onMounted, ref} from "vue";
import DatePagination from "./DatePagination.vue";
import SquadValuesTableRow from "./SquadValuesTableRow.vue";

export default {
    components: {SquadValuesTableRow, DatePagination},
    setup(props) {
        const squadPickerStore = useSquadPickerStore()

        const costs = computed(() => {
            return squadPickerStore.costs
        })

        const players = computed(() => {
            return squadPickerStore.originalPlayers
        })

        const saleValues = computed(() => {
            return squadPickerStore.saleValues
        })

        const loaded = computed(() => {
            return players.value instanceof Object && Object.values(players.value).length > 0
        })

        return {loaded, costs, players, saleValues}
    }
}
</script>
