<template>
    <div>
        <div class="px-3 paragraphs">
            <p>Welcome to the Prediction League. Taking part is super easy. Just select the name of the team you think will win, or select "draw" if you think it will be a draw.</p>
            <p>You score 1 point for every correct prediction.</p>
        </div>
        <div class="grid grid-cols-1 xl:grid-cols-2">
            <div v-if="predictions">
                <prediction-fixtures
                    :predictions="predictions"
                    @savePrediction="savePrediction"
                ></prediction-fixtures>
            </div>
            <div v-if="leaderboard" class="leaderboard">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Points</th>
                            <th>Predictions</th>
                        </tr>
                    </thead>
                    <tr v-for="row in leaderboard">
                        <th>
                            #{{ row.rank }}
                        </th>
                        <td>
                            {{ row.entry ? row.entry.name : row.user.name }}
                        </td>
                        <td class="text-center">
                            {{ row.points }}
                        </td>
                        <td class="text-center">
                            {{ row.predictions }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {onMounted, ref, computed} from "vue";
import {useSeasonStore} from "../stores/seasonStore.js";
import {useDefaultStore} from "../stores/defaultStore.js";
import predictionsQuery from "../queries/predictionsQuery.js";
import PredictionFixtures from "../components/PredictionFixtures.vue";
import {useSeoMeta} from "@unhead/vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    components: {PredictionFixtures},
    setup() {
        const store = useDefaultStore()
        const seasonStore = useSeasonStore()

        useSeoMeta({
            title: () => 'Predictions | ' + seasonStore.season.name,
            description: () => 'Prediction league for ' + seasonStore.season.name
        })

        const seasonId = computed(() => {
            return seasonStore.seasonId
        })

        const user = computed(() => {
            return store.authUser
        })

        const predictions = ref(undefined)
        const leaderboard = ref(undefined)

        onMounted(() => {
            predictionsQuery.user(seasonId.value, user.value.id)
                .then(response => {
                    predictions.value = _.keyBy(response, 'fixture_id') || {}
                })

            predictionsQuery.season(seasonId.value)
                .then(response => {
                    leaderboard.value = response
                })
        })

        const savePrediction = (payload) => {
            predictionsQuery.save(payload)
                .then(prediction => {
                    predictions.value = {
                        ...predictions.value,
                        [prediction.fixture_id]: prediction
                    }

                })
                .catch(error =>  {
                    useFlashStore().axiosError(error, 'Error saving prediction')
                })

        }

        return {predictions, leaderboard, savePrediction}
    }
}
</script>
