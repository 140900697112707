import {defineStore} from 'pinia'

export const useGodStore = defineStore('godStore', {
    state: () => ({
        loaded: false,
        seasons: undefined,
    }),
    actions: {
        async init(slug, seasonId) {
            const response = await axios.get('/api/init-god')
            this.loaded = true
            this.seasons = response.data.seasons
        },
        getSeason(seasonId) {
            return _.find(this.seasons, {id: parseInt(seasonId)})
        },
        async createSeason(attributes) {
            const response = await axios.post('/api/seasons', attributes)

            this.seasons.push(response.data.data)

            return response.data.data
        },
        async updateSeason(season, attributes) {
            const response = await axios.patch('/api/seasons/' + season.id, attributes)

            this.seasons = _.replaceInArray(this.seasons, {id: season.id}, response.data.data)

            return response.data.data
        }
    },
})
