<template>
    <div v-if="activeDate && changes" id="price-change-stats" class="box">
        <div class="flex-default">
            <h2>Price Changes - {{ activeDate.format('MMM D') }}</h2>
            <date-pagination
                :active="activeDate"
                :min="minDate"
                :max="maxDate"
                @goTo="setDate($event)"
            ></date-pagination>
        </div>
        <div v-if="changes.length > 0" class="flex justify-center w-full">
            <table :key="activeDate.toISOString()" class="w-full">
                <tbody>
                <price-change-stats-row
                    v-for="change in changes"
                    :key="change.player_season_id"
                    :change="change">
                </price-change-stats-row>
                </tbody>
            </table>
        </div>
        <div v-else>
            No players changed price today
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import dayjs from "dayjs";
import PriceChangeStatsRow from "./PriceChangeStatsRow.vue";
import DatePagination from "./DatePagination.vue";

export default {
    components: {DatePagination, PriceChangeStatsRow},
    setup(props) {
        const store = useSeasonStore()

        onMounted(() => {
            getChanges()
        })

        const getChanges = () => {
            axios
                .get('/api/price-changes', {
                    params: {
                        season_id: store.seasonId,
                        date: activeDate.value.format('YYYY-MM-DD')
                    }
                })
                .then(response => {
                    changes.value = response.data.changes
                    minDate.value = dayjs(response.data.min_date)
                })
        }

        const setDate = (date) => {
            activeDate.value = date
            getChanges()
        }

        const changes = ref([])
        const maxDate = ref(dayjs().subtract(1, 'day'))
        const minDate = ref(dayjs().subtract(1, 'day'))
        const activeDate = ref(maxDate.value)

        return {minDate, maxDate, activeDate, changes, setDate}
    }
}
</script>
