<template>
    <div v-if="leagueNotFound" class="p-3">
        <h1>League Not Found</h1>
        <div class="paragraphs">
            <p>Sorry, we couldn't find the league you were looking for. Please check with whoever gave you the link for the correct link.</p>
        </div>
    </div>
    <router-view
        v-else-if="league"
        :league="league"
        @leagueUpdated="updateLeague($event)"
    ></router-view>
</template>
<script>
import {computed, onMounted, ref} from "vue";
import miniLeagueQuery from "@/queries/miniLeagueQuery";
import {useFlashStore} from "@/stores/flashStore";

export default {
    props: {
        code: {
            type: String,
            require: true
        }
    },
    setup(props) {
        const flashStore = useFlashStore()

        const league = ref(null)
        const leagueNotFound = ref(false)

        onMounted(() => {
            miniLeagueQuery.get(props.code)
                .then(response => {
                    league.value = response.mini_league
                })
                .catch(error => {
                    leagueNotFound.value = true
                    flashStore.setFlash('error', 'Failed to load mini league :(')
                })
        })

        const updateLeague = (updatedLeague) => {
            console.log(updatedLeague)
            league.value = updatedLeague
        }

        return {leagueNotFound, league, updateLeague}
    }
}
</script>
