<template>
    <nav-bar v-if="!seasonId"></nav-bar>
    <update-entry-form
        v-if="seasonId"
    ></update-entry-form>
    <div id="settings-page" class="grid grid-cols-1 lg:grid-cols-2">
        <div id="account-form" class="box form">
            <h1>Update Account</h1>
            <form @submit.prevent="submitAccountForm">
                <div class="mb-3">
                    <label for="register_name">Personal Name</label>
                    <input type="text" id="register_name"
                           placeholder="" required
                           v-model="name">
                </div>
                <div class="mb-3">
                    <label for="register_email">Your email</label>
                    <input type="email" id="register_email"
                           placeholder="" required
                           v-model="email">
                </div>
                <div class="mb-3">
                    <label>Send Me Emails</label>
                    <div class="radios">
                        <input class="inline w-auto" id="email_status_no" name="email_status" type="radio" v-model="email_status" value="unsubscribed" />
                        <label class="inline w-auto pl-3" for="email_status_no">No</label>
                        <input id="email_status_yes" name="email_status" type="radio" v-model="email_status" value="subscribed" />
                        <label for="email_status_yes">Yes</label>
                    </div>
                </div>
                <div v-if="email_status === 'bounced'" class="danger-text mb-3">
                    We have received responses that emails to you have been bouncing so we have stopped sending emails. Choose yes above to start again.
                </div>
                <div v-if="email_status === 'complained'" class="danger-text mb-3">
                    We have received a complaint from your email provider. Perhaps you marked us as spam? We have stopped sending you emails. Choose yes above to start again.
                </div>
                <div class="mb-3">
                    <button type="submit">
                        Update Account
                    </button>
                </div>
            </form>
        </div>
        <div id="password-form" class="box form">
            <h1>Update Password</h1>
            <form @submit.prevent="submitPasswordForm">
                <div class="mb-3">
                    <label for="register_password">New password</label>
                    <input type="password" id="register_password"
                           min="6"
                           required
                           v-model="password">
                </div>
                <div class="mb-3">
                    <label for="register_password_confirmation">Repeat password</label>
                    <input type="password" id="register_password_confirmation"
                           min="6"
                           required
                           v-model="password_confirmation">
                </div>
                <div class="mb-3">
                    <button type="submit">
                        Update Password
                    </button>
                </div>
            </form>
        </div>
        <div class="box red-gradient">
            <h1>Logout</h1>
            <p class="mb-3">Use the link below if you would like to log out of your account.</p>
            <button @click.prevent="logout">Logout</button>
        </div>
    </div>
</template>
<script>
import {computed, onMounted, ref} from 'vue'
import {useDefaultStore} from "../stores/defaultStore";
import NavBar from "../components/NavBar.vue";
import UpdateEntryForm from "@/components/UpdateEntryForm.vue";
import {useSeoMeta} from "@unhead/vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    components: {UpdateEntryForm, NavBar},
    props: {
        seasonId: {
            required: false,
            type: [Number, String]
        }
    },
    setup() {
        useSeoMeta({
            title: () => 'Settings',
            description: () => 'Update your settings.'
        })

        const store = useDefaultStore()

        const authUser = computed(() => {
            return store.authUser
        })

        const name = ref(authUser.value.name)
        const email = ref(authUser.value.email)
        const email_status = ref(authUser.value.email_status)
        const password = ref('')
        const password_confirmation = ref('')

        const submitAccountForm = () => {
            store
                .updateAuth({
                    name: name.value,
                    email: email.value,
                    email_status: email_status.value
                })
                .then(response => {
                    useFlashStore().setFlash('success', 'Account Updated')
                })
                .catch(error =>  {
                    useFlashStore().axiosError(error, 'Error Updating Account')
                })
        }

        const submitPasswordForm = () => {
            if (password.value === password_confirmation.value) {
                store
                    .updateAuth({
                        password: password.value
                    })
                    .then(response => {
                        useFlashStore().setFlash('success', 'Password Updated')
                    })
                    .catch(error =>  {
                        useFlashStore().axiosError(error, 'Failed to update password')
                    })
            } else {
                useFlashStore().setFlash('error', 'Passwords Do Not Match')
            }
        }

        const logout = () => {
            store.logout()
        }

        return {authUser, name, email, email_status, password, password_confirmation, submitAccountForm, submitPasswordForm, logout}
    }
}
</script>
