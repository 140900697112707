<template>
    <div v-if="gameweeks && gameweeks.length > 0" id="gameweeks-table" class="box green-gradient">
        <h2>Doubles and Blanks</h2>
        <p>These are gameweeks where teams play twice or not at all. Usually a good time to play your chips!</p>
        <table class="w-100">
            <thead>
                <tr>
                    <th>GW</th>
                    <th>Deadline</th>
                    <th>Doubles</th>
                    <th>Blanks</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="gameweek in gameweeks">
                    <td>
                        GW {{ gameweek.number }}
                    </td>
                    <td>
                        {{ gameweek.pretty_locked_at }}
                    </td>
                    <td>
                        <i v-if="gameweek.doubles_count" class="fa-solid fa-check"></i>
                        <i v-else class="fa-solid fa-xmark"></i>
                    </td>
                    <td>
                        <i v-if="gameweek.blanks_count" class="fa-solid fa-check"></i>
                        <i v-else class="fa-solid fa-xmark"></i>

                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref} from "vue";
import FixtureListRow from "./FixtureListRow.vue";
import dayjs, {isDayjs} from 'dayjs';
import GameweekPagination from "./GameweekPagination.vue";

export default {
    methods: {isDayjs},
    components: {GameweekPagination, FixtureListRow},
    props: {
        showAll: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const gameweeks = computed(() => {
            return _.map(_.filter(store.gameweeks, gw => {
                return props.showAll || gw.blanks_count > 0 || gw.doubles_count > 0
            }), gw => {
                return {
                    ...gw,
                    pretty_locked_at: dayjs(gw.locked_at).format('ddd D MMM HH:mm')
                }
            })
        })

        return {gameweeks}
    }
}
</script>
