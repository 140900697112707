<template>
    <player-profile :player="player"></player-profile>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted} from "vue";
import PlayerProfile from "../components/PlayerProfile.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {PlayerProfile},
    props: {
        playerId: {
            type: [String, Number],
            required: true
        }
    },
    setup(props) {
        const store = useSeasonStore()
        const player = computed(() => {
            return store.players[props.playerId]
        })

        useSeoMeta({
            title: () => player.value.name + ' | Fantasy ' + store.season.name,
            description: () => player.value.name + ' in Fantasy ' + store.season.name
        })

        return {player}
    }
}
</script>
