<template>
    <tr>
        <td :class="diff === 0 ? '' : (diff > 0 ? 'success-text' : 'danger-text')">
            {{ diff !== 0 ? diffText : '' }}
        </td>
        <td>
            $ {{ prettyCost }}
        </td>
        <td>
            $ {{ prettyCurrentValue }}
        </td>
        <td>
            $ {{ prettySaleValue }}
        </td>
        <th>
            <div class="flex items-center">
                <div class="player-team-logo">
                    <img v-if="team && team.image_path" :src="team.image_path"/>
                </div>
                <div class="grow">
                    <router-link :to="{name:'player', params: {playerId: player.id}}">
                        {{ player.name }}
                    </router-link>
                    <small class="player-details block">
                        <span>{{ player.position }}</span>
                        <span>{{ team.short_name }}</span>
                    </small>
                </div>
            </div>
        </th>
    </tr>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import PickerPlayer from "./PickerPlayer.vue";
import GameweekPagination from "./GameweekPagination.vue";

export default {
    components: {GameweekPagination, PickerPlayer},
    props: {
        player: {
            type: Object,
            required: true
        },
        cost: {
            type: Number,
            required: false
        },
        saleValue: {
            type: Number,
            required: false
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const team = computed(() => store.teams[props.player.team_id])

        const diff = computed(() => props.saleValue - props.cost)
        const diffText = computed(() => diff.value > 0 ? '+' + diff.value : diff.value)
        const prettyCurrentValue = computed(() => (props.player.value / 10).toFixed(1))
        const prettyCost = computed(() => (props.cost / 10).toFixed(1))
        const prettySaleValue = computed(() => (props.saleValue / 10).toFixed(1))

        return {team, diff, diffText, prettyCost, prettySaleValue, prettyCurrentValue}
    }
}
</script>
