<template>
    <div class="fixture-header">
        <h3>
            {{ prettyDate }} - {{ prettyTime }}
        </h3>
        <div class="md:grid md:grid-cols-2">
            <div class="md:justify-self-end">
                <h1>
                    <img :src="homeTeam.image_path"/>
                    {{ homeTeam.short_name }}
                    <span class="home-team-score">
                        {{ fixture.home_team_score || 0 }}
                    </span>
                </h1>
            </div>
            <div>
                <h1>
                    <span class="away-team-score">
                        {{ fixture.away_team_score || 0 }}
                    </span>
                    {{ awayTeam.short_name }}
                    <img :src="awayTeam.image_path"/>
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import dayjs from 'dayjs';

export default {
    props: {
        fixture: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const homeTeam = computed(() => {
            return store.teams[props.fixture.home_team_id]
        })

        const awayTeam = computed(() => {
            return store.teams[props.fixture.away_team_id]
        })

        const prettyDate = dayjs(props.fixture.kickoff).format('dddd MMMM D, YYYY');
        const prettyTime = dayjs(props.fixture.kickoff).format('HH:mm')

        return {homeTeam, awayTeam, prettyDate, prettyTime}
    }
}
</script>
