import './bootstrap';
import '../css/app.scss';

import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);

import { createApp, toRaw } from 'vue';

import { createPinia } from 'pinia'
import App from './App.vue'
import {createHead} from "@unhead/vue";

const pinia = createPinia()
const app = createApp(App)
const head = createHead()

import router from './routes.js'

app.use(router)
app.use(pinia)
app.use(head)
/*
import plausible from './plausible';

const plausibleOptions = {
    domain: 'womensfantasysoccer.com',
    hashMode: true,
    trackLocalhost: true,
};
app.use(plausible, plausibleOptions);
*/

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

window.setBugsnagUser = function (id, email, name) {
    Bugsnag.setUser(id, email, name)
}

Bugsnag.start({
    apiKey: '3de92ccb51fb175e49df5f0921084d87',
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.NODE_ENV
})
const bugsnagVue = Bugsnag.getPlugin('vue')
app.use(bugsnagVue)

app.config.errorHandler = (error, instance, info) => {
    Bugsnag.notify(error, function (event) {
        event.addMetadata(
            'instance',
            {
                props: toRaw(instance.$props),
                state: toRaw(instance.$.setupState),
            }
        )
    })

    console.error(error)
}

app.mount('#app')

