<template>
    <nav-bar :title="title" :season-id="season.id">
        <li>
            <router-link :to="{name:'season'}">Dashboard</router-link>
        </li>
        <li v-if="pointsGw">
            <router-link :to="{name:'leaderboard'}">Table</router-link>
        </li>
        <template v-if="authUser">
            <li>
                <router-link :to="{name:'mini-leagues'}">Mini Leagues</router-link>
            </li>
            <template v-if="authEntry && authEntry.status === 'valid'">
                <template v-if="pointsGw">
                    <li>
                        <router-link :to="{name:'points', params: {entryId: authEntry.id}}">Points</router-link>
                    </li>
                </template>
                <template v-if="transfersGw">
                    <li>
                        <router-link :to="{name:'team'}">Team</router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'transfers'}">
                            Transfers
                            <template v-if="season.budget">($ {{ bankValue }})</template>
                        </router-link>
                    </li>
                </template>
            </template>
            <template v-else-if="transfersGw">
                <li>
                    <router-link :to="{name:'transfers'}">Pick Squad</router-link>
                </li>
            </template>
            <li>
                <router-link :to="{name:'predictions'}">Predictions</router-link>
            </li>
            <li>
                <router-link :to="{name:'twenty-one'}">21</router-link>
            </li>
        </template>
    </nav-bar>
</template>

<script>
import {computed} from 'vue'
import {useSeasonStore} from "../stores/seasonStore";
import {useDefaultStore} from "../stores/defaultStore";
import NavBar from "../components/NavBar.vue";

export default {
    components: {NavBar},
    setup(props) {
        const store = useDefaultStore()
        const seasonStore = useSeasonStore()

        const title = computed(() => {
            return 'Fantasy ' + seasonStore.season.name
        })

        const season = computed(() => {
            return seasonStore.season;
        })

        const authUser = computed(() => {
            return store.authUser;
        })

        const authEntry = computed(() => {
            return seasonStore.authEntry;
        })

        const bankValue = computed(() => {
            return seasonStore.authEntry ? seasonStore.authEntry.bank / 10 : null;
        })

        const pointsGw = computed(() => {
            return seasonStore.pointsGameweek
        })

        const transfersGw = computed(() => {
            return seasonStore.transfersGameweek
        })

        return {season, title, authUser, authEntry, bankValue, pointsGw, transfersGw}
    }
}
</script>
