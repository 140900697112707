<template>
    <button
        :type="type"
        data-mdb-ripple="true"
        data-mdb-ripple-color="light"
        class="inline-block px-2.5 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
        :class="classNames"
    >{{ text }}</button>
</template>
<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'button'
        },
        variant: {
            type: String,
            default: 'blue'
        }
    },
    computed: {
        bgColors() {
            return {
                green: 'bg-green-600',
                blue: 'bg-blue-500',
                primary: 'bg-blue-500',
            }
        },
        classNames() {
            return this.bgColors[this.variant]
        }
    }
}
</script>
