<template>
    <div>
        <h1>{{ fixture.description }}</h1>
        <div class="mx-3 mb-3 flex items-center">
            <div class="flex">
                Add Player: <select @change="addPlayer">
                    <option v-for="player in players" :value="player.id">
                        {{ player.name }} ({{ player.position }})
                    </option>
                </select>
            </div>
            <p class="ml-3">Press enter on any input to save that row. Or click "Save All" to save all rows. Yellow background indicates data is not saved. All dates and times are in your local time.</p>
            <button @click="saveAll" class="btn success">Save All</button>
        </div>
        <div class="form-table">
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th
                        v-for="column in columns"
                        :data-customTooltip="column.label"
                    >
                        {{ column.abbreviation }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <mod-player-fixture-row
                    v-for="playerFixture in playerFixtures"
                    :key="playerFixture.id"
                    :fixture="fixture"
                    :player-fixture="playerFixture"
                    :columns="columns"
                    v-model="draft[playerFixture.id]"
                    @saveRow="saveRow"
                ></mod-player-fixture-row>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {useFlashStore} from "@/stores/flashStore";
import {computed, ref, onMounted} from "vue";
import {useSeoMeta} from "@unhead/vue";
import ModPlayerFixtureRow from "@/components/ModPlayerFixtureRow.vue";
import playerColumns from "@/config/PlayerColumns";

export default {
    components: {ModPlayerFixtureRow},
    props: {
        fixtureId: {
            type: [String, Number],
            required: true
        }
    },
    setup(props) {
        const seasonStore = useSeasonStore()
        const flashStore = useFlashStore()
        const fixture = computed(() => {
            return seasonStore.fixtures[props.fixtureId]
        })

        useSeoMeta({
            title: () => fixture.value ? 'Mod | ' + fixture.value.description + '| ' + seasonStore.season.name : 'Mod Fixture',
            description: () => fixture.value ? 'Mod Fixture ' + fixture.value.description : ''
        })

        onMounted(() => {
            axios
                .get('/api/fixture/' + props.fixtureId + '/stats')
                .then(response => {
                    setPlayerFixtures(response.data.playerFixtures)
                })
        })

        const setPlayerFixtures = (payload) => {
            playerFixtures.value = _.orderBy(
                payload,
                [
                    playerFixture => playerFixture.team_id === fixture.value.home_team_id ? 0 : 1,
                    playerFixture => seasonStore.players[playerFixture.player_season_id].position_sort,
                    playerFixture => seasonStore.players[playerFixture.player_season_id].name
                ],
                [
                    'asc',
                    'asc',
                    'asc'
                ]
            )
            draft.value = _.keyBy(payload, 'id')
        }

        const players = computed(() => {
            return _.sortBy(
                _.filter(seasonStore.players, player => {
                    return !existingPlayerIds.value.includes(player.id)
                        && player.status !== 'duplicate'
                        && fixture.value
                        && [fixture.value.home_team_id, fixture.value.away_team_id].includes(player.team_id)
                }),
                'name'
            )
        })

        const playerFixtures = ref([])
        const draft = ref({})

        const columns = playerColumns.filterByModel('player_fixture_form');

        const existingPlayerIds = computed(() => _.map(playerFixtures.value, 'player_season_id'))

        const addPlayer = (event) => {
            axios
                .post('/api/player-fixtures', {
                    fixture_id: fixture.value.id,
                    player_season_id: event.target.value
                })
                .then(response => {
                    flashStore.setFlash('success', 'Player Added')

                    setPlayerFixtures([...playerFixtures.value, response.data.data])
                })
                .catch(error => flashStore.axiosError(error, 'Adding Player Failed'));
        }

        const saveRow = (playerFixtureId) => {
            axios
                .patch('/api/player-fixtures/' + playerFixtureId, draft.value[playerFixtureId])
                .then(response => {
                    flashStore.setFlash('success', 'Player Fixture Updated')

                    playerFixtures.value = _.replaceInArray(playerFixtures.value, {id: playerFixtureId}, response.data.data)
                    draft.value[playerFixtureId] = response.data.data
                })
                .catch(error => flashStore.axiosError(error, 'Saving Player Fixture Failed'));
        }

        const saveAll = () => {
            axios
                .patch('/api/player-fixtures', draft.value)
                .then(response => {
                    flashStore.setFlash('success', 'All Player Fixtures Updated')

                    setPlayerFixtures(response.data.data)
                })
                .catch(error => flashStore.axiosError(error, 'Saving Player Fixtures Failed'));
        }

        return { fixture, players, playerFixtures, draft, columns, addPlayer, saveAll, saveRow };
    }
}
</script>
