<template>
    <nav-bar></nav-bar>
    <div id="feedback-form" class="form box">
        <h1>Send Us Feedback</h1>
        <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="name">Your name</label>
                <input type="text" id="name"
                       class=""
                       placeholder="" required
                       v-model="name">
            </div>
            <div class="mb-3">
                <label for="email">Your email</label>
                <input type="email" id="email"
                       class=""
                       placeholder="" required
                       v-model="email">
            </div>
            <div class="mb-3">
                <label for="message">Your message</label>
                <textarea id="message" required v-model="message"></textarea>
            </div>
            <div class="mb-3">
                <button :disabled="sending">
                    {{ sending ? 'Sending...' : 'Send Feedback' }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import {ref} from "vue";
import NavBar from "../components/NavBar.vue";
import {useSeoMeta} from "@unhead/vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    components: {NavBar},
    setup() {
        useSeoMeta({
            title: 'Feedback',
            description: 'Contact %siteName with your feedback'
        })

        const sending = ref(false)
        const name = ref('')
        const email = ref('')
        const message = ref('')

        const submitForm = () => {
            sending.value = true
            axios.post('/api/feedback', {
                name: name.value,
                email: email.value,
                message: message.value
            })
                .then(response => {
                    useFlashStore().setFlash('success', 'Feedback Sent')
                    name.value = ''
                    email.value = ''
                    message.value = ''
                })
                .catch(error =>  {
                    useFlashStore().axiosError(error, 'Error submitting feedback')
                })
                .finally(() => {
                    sending.value = false
                })
        }

        return {sending, name, email, message, submitForm}
    }
}
</script>
