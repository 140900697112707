<template>
    <div id="player-histories">
        <player-filter
            v-model="filter"
        ></player-filter>
        <div v-for="team in teams">
            <div class="box blue-gradient">
                <div class="header">
                    <h1>{{ team.name }}</h1>
                </div>
            </div>
            <div
                v-for="player in groupedPlayers[team.id]"
                :key="player.id"
                class="player-profile"
            >
                <div class="header">
                    <h1>
                        <img :src="team.image_path" class="flag"/>
                        {{ player ? player.name : 'Error' }}
                    </h1>
                </div>
                <div class="details">
                    <ul>
                        <li>
                            {{ player.position }}
                        </li>
                        <li>
                            {{ team.name }}
                        </li>
                        <li>
                            {{ player.points }} Points
                        </li>
                    </ul>
                </div>
                <player-historical-stats-table
                    v-if="historicalStats && historicalStats.hasOwnProperty(player.player_id)"
                    :rows="historicalStats[player.player_id]"
                    :player="player">
                </player-historical-stats-table>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, computed} from "vue";
import PlayerHistoricalStatsTable from "@/components/PlayerHistoricalStatsTable.vue";
import {useSeasonStore} from "@/stores/seasonStore";
import PlayerFilter from "@/components/PlayerFilter.vue";
import PlayerListRow from "@/components/PlayerListRow.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {PlayerListRow, PlayerFilter, PlayerHistoricalStatsTable},
    emits: ['close'],
    setup() {
        const seasonStore = useSeasonStore()

        useSeoMeta({
            title: () => 'Player Histories | ' + seasonStore.season.name,
            description: () => 'Historical stats for all players in Fantasy ' + seasonStore.season.name
        })

        onMounted(() => {
            axios
                .get('/api/players/historical-stats/season/' + seasonStore.seasonId)
                .then(response => {
                    historicalStats.value = _.groupBy(response.data.rows, 'player_id')
                })
        })

        const historicalStats = ref(undefined)

        const teams = computed(() => {
            return _.filter(
                seasonStore.teams,
                team => {
                    return filter.value.team_id === 'ALL' || parseInt(filter.value.team_id) === parseInt(team.id)
                }
            )
        })

        const groupedPlayers = computed(() => {
            return _.groupBy(seasonStore.filterPlayers(filter.value), 'team_id')
        })

        const filter = ref({
            team_id: 'ALL',
            value: 'ALL',
            position: 'ALL',
            sort: 'position',
            sort_dir: 'asc',
            status: 'active'
        })

        return {historicalStats, teams, groupedPlayers, filter}
    }
}
</script>
