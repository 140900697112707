<template>
    <div v-if="hasPicks" class="fixture-list-players">
        <div class="fixture-row">
            <div class="home-team">
                <template v-if="groupedPicks.hasOwnProperty(fixture.home_team_id)">
                    <template v-for="entryPick in groupedPicks[fixture.home_team_id]">
                        <fixture-list-player-row
                            :fixture="fixture"
                            :player-fixture="keyedPlayerFixtures[entryPick.player_season_id]"
                            :pick="entryPick"
                            :player="players[entryPick.player_season_id]"
                        ></fixture-list-player-row>
                    </template>
                </template>
            </div>

            <div class="away-team">
                <template v-if="groupedPicks.hasOwnProperty(fixture.away_team_id)">
                    <template v-for="entryPick in groupedPicks[fixture.away_team_id]">
                        <fixture-list-player-row
                            :fixture="fixture"
                            :player-fixture="keyedPlayerFixtures[entryPick.player_season_id]"
                            :pick="entryPick"
                            :player="players[entryPick.player_season_id]"
                        ></fixture-list-player-row>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import FixtureListPlayerRow from "@/components/FixtureListPlayerRow.vue";

export default {
    components: {FixtureListPlayerRow},
    props: {
        fixture: {
            type: Object,
            required: true
        },
        playerFixtures: {
            type: Array,
            default: null
        },
        picks: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const players = computed(() => {
            return _.pick(store.players, _.map(props.picks, 'player_season_id'))
        })

        const keyedPlayerFixtures = computed(() => {
            return _.keyBy(props.playerFixtures, 'player_season_id')
        })

        const groupedPicks = computed(() => {
            return _.groupBy(props.picks, pick => {
                return keyedPlayerFixtures.value.hasOwnProperty(pick.player_season_id)
                    ? keyedPlayerFixtures.value[pick.player_season_id].team_id
                    : players.value[pick.player_season_id].team_id
            })
        })

        const hasPicks = computed(() => {
            return _.filter(_.keys(groupedPicks.value), teamId => {
                return [props.fixture.home_team_id, props.fixture.away_team_id].includes(parseInt(teamId))
            }).length > 0
        })

        return {groupedPicks, keyedPlayerFixtures, players, hasPicks}
    }
}
</script>
