<template>
    <tr v-if="team">
        <td>
            {{ position }}
        </td>
        <th>
            {{ team.name }}
        </th>
        <td>
            {{ row.played }}
        </td>
        <td>
            {{ row.won }}
        </td>
        <td>
            {{ row.drawn }}
        </td>
        <td>
            {{ row.lost }}
        </td>
        <td>
            {{ row.gf }}-{{ row.ga }}
        </td>
        <td>
            {{ row.gd }}
        </td>
        <td>
            {{ row.points }}
        </td>
        <td>
            {{ row.points_per_game }}
        </td>
    </tr>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";

export default {
    props: {
        row: {
            type: Object,
            required: true
        },
        position: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const team = computed(() => {
            return store.teams[props.row.team_id]
        })

        return {team}
    }
}
</script>
