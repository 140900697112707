<template>
    <div id="auth-entries-page">
        <nav-bar></nav-bar>
        <div v-if="authEntries === null">
            Loading...
        </div>
        <div v-else-if="authEntries.length === 0">
            You have not entered any leagues
        </div>
        <div v-else>
            <div id="all-leagues-table" class="box blue-gradient">
                <h1>My Leagues</h1>
                <div class="leaderboard">
                    <table>
                        <thead>
                        <tr>
                            <th>Season</th>
                            <th>League</th>
                            <th>Name</th>
                            <th>Rank</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="season in seasons">
                            <template v-if="season.status === 'active' && authEntries.hasOwnProperty(season.id)">
                                <tr>
                                    <th :rowspan="miniLeagues.hasOwnProperty(season.id) ? miniLeagues[season.id].length + 1 : 1">
                                        <router-link :to="{name:'season', params:{seasonId: season.id}}" class="mx-2">
                                            {{ season.name }}
                                        </router-link>
                                    </th>
                                    <th>
                                        <span class="mx-2">
                                            Overall
                                        </span>
                                    </th>
                                    <th>
                                        <router-link :to="{name:'team', params:{seasonId: season.id}}" class="mx-2">
                                            {{ authEntries[season.id].name }}
                                        </router-link>
                                    </th>
                                    <td>
                                        <span  class="mx-2">
                                            {{ authEntries[season.id].rank }}
                                        </span>
                                    </td>
                                </tr>
                                <template v-if="miniLeagues.hasOwnProperty(season.id)">
                                    <tr v-for="league in miniLeagues[season.id]">
                                        <th>
                                            <router-link :to="{name:'mini-league', params:{seasonId: season.id, code: league.code}}" class="mx-2">
                                                {{ league.name }}
                                            </router-link>
                                        </th>
                                        <th>
                                            <router-link :to="{name:'team', params:{seasonId: season.id}}" class="mx-2">
                                                {{ authEntries[season.id].name }}
                                            </router-link>
                                        </th>
                                        <td>
                                            <span class="mx-2">
                                                {{ league.pivot.position }}
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useDefaultStore} from "../stores/defaultStore";
import {computed, onMounted, ref} from "vue";
import NavBar from "../components/NavBar.vue";
import {useSeoMeta} from "@unhead/vue";
import miniLeagueQuery from "@/queries/miniLeagueQuery";

export default {
    components: {NavBar},
    setup() {
        const defaultStore = useDefaultStore()

        useSeoMeta({
            title: () => 'My Teams',
            description: () => 'View all your teams across all competitions.'
        })

        onMounted(() => {
            axios
                .get('/api/entry/auth')
                .then(response => {
                    authEntries.value = _.keyBy(response.data, 'season_id')
                })

            miniLeagueQuery
                .getUserEntries(defaultStore.authUser.id)
                .then(response => {
                    miniLeagues.value = _.groupBy(response.data, 'season_id')
                })
        })

        const seasons = computed(() => {
            return _.keyBy(defaultStore.seasons, 'id')
        })
        const authEntries = ref({})
        const miniLeagues = ref({})

        return {seasons, authEntries, miniLeagues}
    }
}
</script>
